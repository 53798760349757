import React, { Component } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import { withRouter } from "react-router-dom";
import "react-select/dist/react-select.css";
import AppCanvasLoader from "../../../../components/App/AppCanvasLoader";
import ToggleSwitch from "../../../../components/ToggleSwitch";
import * as API from "../../../../utils/api";
import ObjectDebtList from "../../../ObjectList/ObjectDebtList";
import Modal from "../Modal";

class TeamMemberContactPreferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowToggle: false,
      selectedCheckBox: [],
      consumers: [],
      selected_contacts: [],
      isLoading: true,
      offset: 0,
      showLoader: false,
      buttonVisibility: false,
      hasError: false,
      is_checked: false,
      current_list: [],
      searchEmpText: "",
    };
  }

  componentDidMount() {
    let { user, selected_contacts } = this.props;
    this.setState({
      selected_contacts: selected_contacts,
    });
    if (user) {
      this.getSelectedContacts(false);
    }
    if (this.props.employees) {
      this.props.employees.map((item) => {
        if (item.is_assigned) {
          let index = this.state.selected_contacts.indexOf(item.user_id);
          if (index === -1) {
            this.state.selected_contacts.push(item.user_id);
          }
        } else {
          let index = this.state.selected_contacts.indexOf(item.user_id);
          if (index > -1) {
            this.state.selected_contacts.splice(index, 1);
          }
        }
      });
      this.setState({
        selected_contacts: this.state.selected_contacts,
      });
    }
  }

  handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    // let data = this.removeDuplicate(this.state.selected_contacts);
    this.setState({ current_list: [] });
    let data = this.props.employees.length
      ? this.props.employees.filter((item) => item.is_assigned)
      : this.state.consumers.filter((item) => item.is_selected);
    let list = [];
    list = data.map((item) => {
      return item.user_id;
    });

    this.props.hideModal(list);
  };

  getSelectedContacts = (reload) => {
    let { user, item } = this.props;
    this.setState((state) => ({ ...state, showLoader: true }));
    API.getSelectedContactsunassigned(user, item.id, this.state.offset).then(
      (data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          let consumers = reload ? this.state.consumers : false;
          let newData = consumers ? consumers.concat(data.data) : data.data;
          newData.map((item) => {
            this.props.new_selected_list &&
              this.props.new_selected_list.map((list, i) => {
                if (list === item.user_id) {
                  item.is_selected = true;
                }
              });
          });
          this.setState(
            (state) => ({
              ...state,
              consumers: newData,
              isLoading: false,
              hasMoreData: data.data.length < 10 ? false : true,
              showLoader: false,
            }),
            () => {
              this.setLocalId();
            }
          );
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    );
  };

  setLocalId = () => {
    let { consumers, selected_contacts } = this.state;
    if (consumers && consumers.length) {
      consumers.map((consumer, index) => {
        consumer.localId = index;
        if (selected_contacts && selected_contacts.length) {
          let index = selected_contacts.findIndex(
            (o) => o === consumer.user_id
          );
          if (index > -1) {
            consumer.is_selected = true;
          } else {
            consumer.is_selected = false;
          }
        }
      });
    }

    this.setState({
      consumers: consumers,
    });
  };

  removeDuplicate = (data) => {
    if (data) {
      return data.filter((value, index) => data.indexOf(value) === index);
    }
  };

  turnOnChecked = () => {
    this.state.consumers.map((consumer) => {
      if (consumer.is_selected) {
        consumer.is_selected = true;
        let index = this.state.selected_contacts.indexOf(consumer.user_id);
        if (index === -1) {
          this.state.selected_contacts.push(consumer.user_id);
        }
      }
    });
    this.setState({
      consumers: this.state.consumers,
      selected_contacts: this.state.selected_contacts,
    });
  };

  turnOffChecked = (event) => {
    this.state.consumers.map((consumer) => {
      if (consumer.is_selected) {
        consumer.is_selected = false;
        let index = this.state.selected_contacts.indexOf(consumer.user_id);
        if (index > -1) {
          this.state.selected_contacts.splice(index, 1);
        }
      }
    });
    this.setState({
      consumers: this.state.consumers,
      selected_contacts: this.state.selected_contacts,
    });
  };

  checkBoxToggle = (isChecked, user_id) => {
    const updatedEmployees = this.props.employees.map((employee) => {
      if (employee.user_id === user_id) {
        employee.is_assigned = isChecked;
      }
      return employee;
    });

    // Update current_list based on user_id
    let current_list = [...this.state.current_list];
    const employeeIndex = this.props.employees.findIndex(
      (emp) => emp.user_id === user_id
    );

    if (employeeIndex !== -1) {
      let ind = current_list.indexOf(user_id);
      if (ind === -1 && isChecked) {
        current_list.push(user_id); // Add user_id to current_list
      } else if (!isChecked) {
        current_list.splice(ind, 1); // Remove user_id from current_list
      }
    }

    // Update state
    this.setState({
      current_list,
      hasError: false,
    });
  };

  toggleButton = (isChecked, user_id) => {
    // Update the selected status for the consumer
    const updatedConsumers = this.state.consumers.map((consumer) => {
      if (consumer.user_id === user_id) {
        consumer.is_selected = isChecked;
      }
      return consumer;
    });

    // Ensure selected_list is initialized properly
    let selected_list = Array.isArray(this.state.selected_list)
      ? [...this.state.selected_list]
      : [];

    // Find the consumer's index in the list
    const consumerIndex = this.state.consumers.findIndex(
      (consumer) => consumer.user_id === user_id
    );

    if (consumerIndex !== -1) {
      let ind = selected_list.indexOf(user_id);
      if (ind === -1 && isChecked) {
        selected_list.push(user_id); // Add user_id to selected_list
      } else if (!isChecked && ind !== -1) {
        selected_list.splice(ind, 1); // Remove user_id from selected_list
      }
    }

    // Update state with the modified list and reset errors
    this.setState({
      consumers: updatedConsumers,
      selected_list,
      hasError: false,
    });
  };

  multiSelect = () => {
    if (this.state.selectedCheckBox.length > 1) {
      let first =
        this.state.selectedCheckBox[this.state.selectedCheckBox.length - 2];
      let last =
        this.state.selectedCheckBox[this.state.selectedCheckBox.length - 1];
      if (first > last) {
        let temp = first;
        first = last;
        last = temp;
      }
      this.state.consumers.map((consumer) => {
        if (consumer.localId >= first && consumer.localId <= last) {
          consumer.is_selected = true;
        }
      });
    }
    this.setState({ consumers: this.state.consumers });
  };

  onCheckboxClick = (event, item) => {
    const consumerId = event.target.name;
    let isShiftKey = event.shiftKey;
    this.state.consumers.map((consumer) => {
      if (consumer.user_id == consumerId) {
        if (event.target.checked) {
          consumer.is_selected = true;
          this.state.selectedCheckBox.push(item.localId);
        } else {
          consumer.is_selected = false;
          this.state.selectedCheckBox = [];
        }
      }
    });
    this.setState({ selectedCheckBox: this.state.selectedCheckBox }, () => {
      isShiftKey && this.multiSelect();
      this.buttonVisibility();
    });
    if (!event.target.checked) {
      this.turnOffChecked(event);
    } else if (event.target.checked) {
      this.turnOnChecked(event);
    }
  };

  buttonVisibility = () => {
    let { consumers, buttonVisibility } = this.state;
    let flag = false;
    consumers.map((item) => {
      if (item.is_selected) {
        flag = true;
      }
      this.setState({ buttonVisibility: flag });
    });
  };

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
        }),
        () => {
          this.getSelectedContacts(true);
        }
      );
    }
  };

  getObjectListData = (data) => {
    const { employees } = this.props;
    const { searchEmpText } = this.state;

    // Function to filter and map data
    const filterAndMapData = (list, isEmployeeList) => {
      return list
        .filter((item) =>
          item.display_name
            .replace(/\s+/g, "")
            .toLowerCase()
            .includes(searchEmpText.replace(/\s+/g, "").toLowerCase())
        )
        .map((item) => {
          return {
            primaryColumn: {},
            secondaryColumns: [
              {
                title: (
                  <input
                    name={item.user_id}
                    key={item.user_id}
                    type='checkbox'
                    value={isEmployeeList ? item.is_assigned : item.user_id}
                    defaultChecked={
                      isEmployeeList ? item.is_assigned : undefined
                    }
                    checked={!isEmployeeList ? item.is_selected : undefined}
                    style={{ WebkitAppearance: "checkbox", width: "20px" }}
                    onChange={(event) =>
                      this.checkBoxToggle
                        ? this.checkBoxToggle(
                            event.target.checked,
                            item.user_id
                          )
                        : this.toggleButton(event.target.checked, item.user_id)
                    }
                  />
                ),
                opClassesPar: "task-checkbox",
              },
              {
                title: item.display_name || "",
              },
            ],
            fullRowIsClickable: false,
            rowType: "",
            optionalRowClasses: "object-row-inner-padding",
          };
        });
    };

    const columns = employees.length
      ? filterAndMapData(employees, true)
      : filterAndMapData(data, false);

    return {
      headings: [],
      columns,
    };
  };

  handleModalClose = () => {
    if (this.state.current_list) {
      this.state.current_list.map((id) => {
        this.props.employees.map((employee) => {
          if (employee.user_id === id) {
            employee.is_assigned = !employee.is_assigned;
          }
        });
      });
    }
    this.props.handleClose();
  };
  handleSearchChange(value) {
    this.setState({
      searchEmpText: value,
    });
  }

  getTopComponent = () => {
    let { selected_contacts, buttonVisibility } = this.state;
    return (
      <div>
        <input
          style={{ padding: "12px", marginBottom: "1rem" }}
          placeholder='Search Accounts'
          value={this.state.searchEmpText}
          onChange={(e) =>
            this.setState({
              searchEmpText: e.target.value,
            })
          }
          // onChange={(e) => this.handleSearchChange(e.target.value)}
        />
        <div className={`contact-count`} style={{ marginBottom: "1rem" }}>
          {selected_contacts &&
          selected_contacts.length &&
          selected_contacts.length == 1
            ? "Selected Contact: "
            : "Selected Contacts: "}
          {selected_contacts && selected_contacts.length
            ? selected_contacts.length
            : 0}
        </div>
      </div>
    );
  };

  render() {
    let { consumers, isLoading, hasError } = this.state;
    return (
      <Modal
        title='Assign Accounts'
        closeOnClickOutside={true}
        hideModal={this.handleSubmit}
        handleModalClose={this.handleModalClose}
        style={{ maxWidth: `35em`, paddingBottom: "1em" }}
        optionalClasses='scroll task-popup-sp'
        titleClasses='modal-header'
      >
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              height: "80%",
            }}
          >
            <div>
              {this.getTopComponent()}
              {consumers && consumers.length > 0 && (
                <BottomScrollListener
                  onBottom={this.handleContainerOnBottom}
                  debounce={0}
                  offset={50}
                >
                  {(scrollRef) => (
                    <div>
                      <ObjectDebtList
                        data={this.getObjectListData(consumers)}
                        scrollRef={scrollRef}
                        removePadding={true}
                        showLoader={this.state.showLoader}
                      />
                    </div>
                  )}
                </BottomScrollListener>
              )}
              {hasError ? (
                <div
                  className='input-container error'
                  style={{
                    marginBottom: "1.5em",
                    marginTop: "-1em",
                    display: "flex",
                  }}
                >
                  <div className='input-error-message' style={{ marginTop: 0 }}>
                    Please Select contact
                  </div>
                </div>
              ) : null}
            </div>
            <div
              className='modal-actions'
              style={{
                position: "sticky",
                bottom: -17,
                background: "#fff",
                padding: "1rem",
                textAlign: "center",
              }}
            >
              <a
                className='cta mg-brand2-color'
                style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
                onClick={(event) => this.handleSubmit(event)}
              >
                Done
              </a>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

export default withRouter(TeamMemberContactPreferences);
