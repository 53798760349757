import moment from "moment";
import React, { Component } from "react";
import shortid from "shortid";
import * as API from "../../../utils/api";
import ClientsCardView from "../../../components/CardView/ClientsCardView";
import InvoiceObjectList from "../../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import HistoryObjectList from "../../../components/ObjectList/ClientsAccounts/HistoryObjectList";
import DropdownButton from "../../../components/DropdownButton";
import MenuItem from "../../../components/MenuItem";
import invoiceIcon from "../../../assets/images/quickbooks/invoice-gray.png";
import paymentIcon from "../../../assets/images/quickbooks/payments-gray.png";
import commIcon from "../../../assets/images/quickbooks/ic_comm_gray.svg";
import PlacementEligibleIcon from "../../../assets/icons/delinquent.png";
import LockIcon from "../../../assets/icons/lock.png";
class InvoiceTasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterBy: "all",
      tasksData: [],
      invoiceFilterBy: "All",
      pastDueCount: 0,
      selectedTaskType: "Internal Notes",
      emailData: [],
      letterData: [],
    };
  }

  componentDidMount() {
    const { invoiceDetails } = this.props;
    this.combileTasksAndPayments();
    // this.getEmailData();
    // this.getLetterData();
    this.setCommunicationData(
      invoiceDetails.notes,
      invoiceDetails.communications.emails,
      invoiceDetails.communications.letters
    );
  }

  componentDidUpdate(props) {
    if (props.invoiceDetails !== this.props.invoiceDetails) {
      this.combileTasksAndPayments();
    }
  }

  combileTasksAndPayments = () => {
    const { invoiceDetails } = this.props;

    let { recent_activities, upcoming_activities } = invoiceDetails;
    let { filterBy, invoiceFilterBy } = this.state;
    let newData = [];
    if (filterBy === "payment") {
      if (invoiceFilterBy === "Pending" || invoiceFilterBy === "Completed") {
        newData = {};
      } else {
        newData = this.getPaymentsTasks(recent_activities);
      }
    } else if (filterBy === "comm") {
      if (invoiceFilterBy === "Pending" || invoiceFilterBy === "Completed") {
        newData = {};
      } else {
        newData = this.getCommsTasks();
      }
    } else if (filterBy === "invoice") {
      let filteredArray;
      if (invoiceFilterBy === "Pending") {
        newData = recent_activities.tasks.filter((o) => {
          return o.status === "Pending";
        });
        //newData = this.getInvoiceTasks(recent_activities);
      } else if (invoiceFilterBy === "Completed") {
        newData = recent_activities.tasks.filter((o) => {
          return o.status === "Completed";
        });
        //newData = this.getInvoiceTasks(recent_activities);
      } else {
        newData = this.getInvoiceTasks(recent_activities);
      }
      if (
        upcoming_activities.length > 0 &&
        (invoiceFilterBy === "All" || invoiceFilterBy === "Pending")
      ) {
        upcoming_activities.filter((o) => {
          if (o.status === "Pending" && !o.auto_send) {
            o.behavior = "manual-invoice";
          }
          newData = newData.concat(o);
        });
      }
    } else if (filterBy === "PastDue" || invoiceFilterBy === "PastDue") {
      newData = recent_activities.tasks.filter((o) => {
        return o.status === "Past Due";
      });
    } else {
      newData = this.getCommsTasks(recent_activities);
      newData = newData.concat(this.getInvoiceTasks(recent_activities));
      newData = newData.concat(this.getPaymentsTasks(recent_activities));
      if (invoiceFilterBy === "Pending") {
        newData = recent_activities.tasks.filter((o) => {
          return o.status === "Pending";
        });
      } else if (invoiceFilterBy === "Completed") {
        newData = recent_activities.tasks.filter((o) => {
          return o.status === "Completed";
        });
      } else {
        newData = this.getCommsTasks(recent_activities);
        newData = newData.concat(this.getInvoiceTasks(recent_activities));
        newData = newData.concat(this.getPaymentsTasks(recent_activities));
      }

      if (
        upcoming_activities.length > 0 &&
        filterBy === "all" &&
        (invoiceFilterBy === "All" || invoiceFilterBy === "Pending")
      ) {
        upcoming_activities.filter((o) => {
          if (o.status === "Pending" && !o.auto_send) {
            o.behavior = "manual-invoice";
          }
          newData = newData.concat(o);
        });
      }
    }
    this.setState({ tasksData: newData });
    // if (filterBy !== "invoice" && filterBy !== "all") {
    //   this.setState({ invoiceFilterBy: "Pending" });
    // }
    let pastDueCount = 0;
    if (recent_activities.tasks.length > 0) {
      recent_activities.tasks.map((row, i) => {
        if (row.status === "Past Due") {
          pastDueCount = pastDueCount + 1;
        }
      });
    }
    this.setState({ pastDueCount: pastDueCount });
    return newData;
  };

  getPaymentsTasks = (recent_activities) => {
    let paymentData = [
      {
        name: "Cancel Payment",
        task_type: "payment",
        behavior: "cancel-payment",
      },
      {
        name: "Refund Payment",
        task_type: "payment",
        behavior: "refund-payment",
      },
      {
        name: "Accept Payment",
        task_type: "payment",
        behavior: "accept-payment",
      },
    ];
    let newArray =
      this.props.hasOwnProperty("recent_activities") &&
      recent_activities.hasOwnProperty("payments")
        ? recent_activities.payments.concat(paymentData)
        : paymentData;
    newArray.reverse();
    return newArray;
  };

  getInvoiceTasks = (recent_activities) => {
    let invoiceData = [
      {
        name: "Cancel Collections Placement",
        task_type: "invoice",
        behavior: "remove-invoice-from-collection",
      },
      {
        name: "ReImport Invoice",
        task_type: "invoice",
        behavior: "re-import",
      },
      {
        name: "Void Invoice",
        task_type: "invoice",
        behavior: "void",
      },
      {
        name: "Delete Invoice",
        task_type: "invoice",
        behavior: "delete_invoice",
      },
      {
        name: "Reverse Credit Memo",
        task_type: "invoice",
        behavior: "credit memo",
      },
    ];
    let filteredInvoiceData = recent_activities.tasks
      .concat(invoiceData)
      .filter((item) => item.name !== "ReImport Invoice");
    return recent_activities && recent_activities.tasks
      ? this.props.user.qb_connect
        ? recent_activities.tasks.concat(invoiceData)
        : filteredInvoiceData
      : invoiceData;
  };

  getCommsTasks = () => {
    return [
      { name: "Send Invoice", behavior: "send-invoice" },
      { name: "Send Email", behavior: "send-email" },
      { name: "Send Custom Email", behavior: "free-form-email" },
      { name: "Send Letter", behavior: "send-letter" },
      { name: "Pause Comms", behavior: "pause-comms" },
    ];
  };

  handleAddTaskNote = (note) => {
    const { user, invoiceDetails } = this.props;
    let payload = {
      invoice_id: invoiceDetails.id,
      note: note,
    };
    API.addInvoiceNote(user, payload).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        //close modal
        this.props.updateModalState(false, "ACCEPT_REJECT_NOTES");
        this.props.reloadData();
      } else {
        //close modal
        this.props.updateModalState(false, "ACCEPT_REJECT_NOTES");
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  sendEmail = () => {
    const { invoiceDetails } = this.props;
    const { user } = invoiceDetails;
    let consumerId = user.user_id ? user.user_id : "";
    let message =
      "Email feature not available with your subscription. Please contact support@makegoodapp.com for access to the Email feature.";
    if (invoiceDetails && invoiceDetails.communication_methods) {
      let i = invoiceDetails.communication_methods.findIndex(
        (o) => o == "Email"
      );
      if (i > -1) {
        return this.renderSendNewMailModal(consumerId);
      } else {
        return this.props.updateModalState(true, "ERROR", {
          message: message,
        });
      }
    } else {
      return this.props.updateModalState(true, "ERROR", {
        message: message,
      });
    }
  };

  sendLetter = () => {
    const { invoiceDetails } = this.props;
    const { user } = invoiceDetails;
    let consumerId = user.user_id ? user.user_id : "";
    let message =
      "Letter feature not available with your subscription. Please contact support@makegoodapp.com for access to the Letter feature.";
    if (invoiceDetails && invoiceDetails.communication_methods) {
      let i = invoiceDetails.communication_methods.findIndex(
        (o) => o == "Letter"
      );
      if (i > -1) {
        return this.renderNewLetterModal(consumerId);
      } else {
        return this.props.updateModalState(true, "ERROR", {
          message: message,
        });
      }
    } else {
      return this.props.updateModalState(true, "ERROR", {
        message: message,
      });
    }
  };

  handlePauseComms = () => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: `Are you sure, you want to pause communications for respective invoice (${this.props.invoiceDetails.id}) ?`,
      confirmClick: this.pauseInvoiceCommunications,
      closeOnClickOutside: true,
    });
  };

  pauseInvoiceCommunications = () => {
    API.pauseInvoiceComms(this.props.user, this.props.invoiceDetails.id).then(
      (data) => {
        if (data.status_code === 200) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
          this.props.getInvoiceDetails();
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
      }
    );
  };

  rowExpanderClick = (event) => {
    const { invoiceDetails } = this.props;
    const { user } = invoiceDetails;
    let consumerId = user.user_id ? user.user_id : "";

    if (event && event.row) {
      if (event.row.behavior && event.row.behavior === "send-letter") {
        this.sendLetter();
      } else if (event.row.behavior && event.row.behavior === "send-email") {
        this.sendEmail();
      } else if (event.row.behavior && event.row.behavior === "pause-comms") {
        this.handlePauseComms();
      } else if (
        event.row.behavior &&
        event.row.behavior === "free-form-email"
      ) {
        let message =
          "Email feature not available with your subscription. Please contact support@makegoodapp.com for access to the Email feature.";
        if (invoiceDetails && invoiceDetails.communication_methods) {
          let i = invoiceDetails.communication_methods.findIndex(
            (o) => o == "Email"
          );
          if (i > -1) {
            this.renderSendFreeMailModal(consumerId);
          } else {
            this.props.updateModalState(true, "ERROR", {
              message: message,
            });
          }
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: message,
          });
        }
      } else if (
        event.row.behavior &&
        event.row.behavior === "accept-payment"
      ) {
        this.handleMakePaymentClick();
      } else if (
        event.row.behavior &&
        event.row.behavior === "refund-payment"
      ) {
        this.handleRefundPaymentClick();
      } else if (
        event.row.behavior &&
        event.row.behavior === "cancel-payment"
      ) {
        this.handleRefundPaymentClick(true);
      } else if (event.row.behavior && event.row.behavior === "re-import") {
        this.props.handleReImportInvoice(event);
      } else if (event.row.behavior && event.row.behavior === "credit memo") {
        this.handleReverseCreditClick(invoiceDetails);
      } else if (event.row.behavior && event.row.behavior === "void") {
        this.props.handleVoidInvoice(event);
      } else if (
        event.row.behavior &&
        event.row.behavior === "delete_invoice"
      ) {
        this.props.handleDeleteInvoice(invoiceDetails.id);
      } else if (event.row.behavior && event.row.behavior === "send-invoice") {
        this.props.updateModalState(true, "CONFIRM_ALERT", {
          message: "Are you sure you want to send invoice?",
          confirmClick: this.handleSendInvoice,
          closeOnClickOutside: true,
        });
      } else if (
        !event.row.behavior &&
        (event.row.name.includes("Invoice Past Due") ||
          event.row.name.includes("Invoice Due") ||
          event.row.name.includes("Send Invoice To Client") ||
          event.row.name.includes("Send Due Date Reminder") ||
          event.row.name.includes("Send Multiple Invoices To Client") ||
          event.row.name.includes("Service Termination Warning") ||
          event.row.name.includes("Service Terminated") ||
          event.row.name.includes("Demand For Payment") ||
          event.row.name.includes("Sent To Collections") ||
          event.row.name.includes("Send Monthly Statements"))
      ) {
        if (event.row.status === "Past Due") {
          //Only for newly created invoice.
          this.props.updateModalState(true, "CONFIRM_ACTION", {
            message: "Are you sure you want to manually complete the task?",
            confirmClick: () => {
              this.openConfirmCompletePastDueModal(event);
            },
            cancelClick: () => {
              this.openConfirmCancelPastDueModal(event);
            },
            closeOnClickOutside: true,
            positiveBTN: "Manually Complete The Task",
            negativeBTN: "Cancel The Task",
          });
        }
      } else if (
        event.row.behavior &&
        event.row.behavior === "manual-invoice"
      ) {
        this.props.updateModalState(true, "CONFIRM_ACTION", {
          message: "Are you sure you want to manually complete the task?",
          confirmClick: () => {
            this.openConfirmCompletePastDueModal(event);
          },
          cancelClick: () => {
            this.openConfirmCancelPastDueModal(event);
          },
          closeOnClickOutside: true,
          positiveBTN: "Manually Complete The Task",
          negativeBTN: "Cancel The Task",
        });
      } else if (
        event.row.behavior &&
        event.row.behavior === "remove-invoice-from-collection" &&
        invoiceDetails.is_placed_collections
      ) {
        this.props.updateModalState(true, "CONFIRM_ALERT", {
          message:
            "Are you sure, you wish to cancel this invoice from collections?",
          confirmClick: () =>
            invoiceDetails.is_placed_collections
              ? this.cancelPlacementFromCollection()
              : () => {},
          closeOnClickOutside: true,
        });
      }
    }
  };

  cancelPlacementFromCollection = () => {
    const { user, invoiceDetails } = this.props;
    API.cancelPlacementFromCollection(user, invoiceDetails.id).then((data) => {
      if (data.status_code === 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        this.props.getInvoiceDetails();
      } else {
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
        });
      }
    });
  };

  openConfirmCompletePastDueModal = (event) => {
    setTimeout(() => {
      this.props.updateModalState(true, "CONFIRM_ALERT", {
        message: "Are you sure you want to manually complete the task?",
        confirmClick: () => this.handlePastDueRemainder(event.row),
        closeOnClickOutside: true,
      });
    }, 300);
  };

  openConfirmCancelPastDueModal = (event) => {
    setTimeout(() => {
      this.props.updateModalState(true, "CONFIRM_ALERT", {
        message: "Are you sure you want to cancel the task?",
        confirmClick: () => this.cancelPastDueRemainder(event.row),
        closeOnClickOutside: true,
      });
    }, 300);
  };

  renderNewLetterModal(consumerId) {
    const { invoiceDetails } = this.props;
    let props = {
      invoiceDetails: invoiceDetails,
      consumerId: consumerId,
      reloadData: this.props.reloadData,
      type: "Invoice",
    };
    this.props.updateModalState(true, "SEND_NEW_LETTER", props);
  }

  renderSendNewMailModal(consumerId) {
    const { invoiceDetails, user } = this.props;
    let props = {
      user: user,
      consumerId: consumerId,
      invoiceDetails: invoiceDetails,
      reloadData: this.props.reloadData,
      type: "Invoice",
    };
    this.props.updateModalState(true, "SEND_NEW_MAIL", props);
  }
  renderSendFreeMailModal(consumerId) {
    const { invoiceDetails, user } = this.props;
    let props = {
      user: user,
      consumerId: consumerId,
      invoiceDetails: invoiceDetails,
      reloadData: this.props.reloadData,
    };
    this.props.updateModalState(true, "FREE_FORM_EMAIL", props);
  }

  handleMakePaymentClick = () => {
    const { invoiceDetails } = this.props;
    let targetDate;
    let criteriaDiscountFlag = false;
    if (
      invoiceDetails &&
      invoiceDetails.criteria_discount_days &&
      invoiceDetails.criteria_discount_days > 0
    ) {
      let startdate = invoiceDetails.date;
      // var new_date = moment(startdate, "YYYY-MM-DD").add(5, "days");
      let nn = moment(
        moment(startdate)
          .add(invoiceDetails.criteria_discount_days, "d")
          .format("YYYY/MM/DD")
      );
      let today = new Date();
      targetDate = moment(nn);
      var month = today.getUTCMonth() + 1; //months from 1-12
      var day = today.getUTCDate();
      var year = today.getUTCFullYear();

      let newdate = year + "/" + month + "/" + day;
      let momentToday = moment(newdate);
      let diffNumber = targetDate.diff(momentToday, "days");
      if (diffNumber > 0) {
        criteriaDiscountFlag = true;
      } else {
        targetDate = "";
      }
    }
    let props = {
      debtInfo: invoiceDetails,
      reloadData: this.props.reloadData,
      targetDate: targetDate,
      criteriaDiscountFlag: criteriaDiscountFlag,
      amountPaid:
        invoiceDetails.payment_history &&
        invoiceDetails.payment_history.length > 0 &&
        invoiceDetails.payment_history.reduce(
          (sum, transaction) => sum + parseFloat(transaction.amount),
          0
        ),
    };

    this.props.updateModalState(true, "MAKE_PAYMENT", props);
  };

  handleSendInvoice = () => {
    const { invoiceDetails } = this.props;

    API.sendInvoice(this.props.user, invoiceDetails.id).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.props.reloadData();
        if (data.status_code === 200) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
        }
        if (data.status_code === 400) {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  handlePastDueRemainder = (data) => {
    const { invoiceDetails } = this.props;
    let payload = {
      task_id: "",
    };
    payload.task_id = data.task_id;
    API.sendPastDueRemainder(this.props.user, payload).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.props.reloadData();
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message
            ? data.message
            : "Invoice task sent successfully.",
        });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  cancelPastDueRemainder = (data) => {
    const { invoiceDetails } = this.props;
    let payload = {
      task_id: "",
    };
    payload.task_id = data.task_id;
    API.cancelPastDueRemainder(this.props.user, payload).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.props.reloadData();
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message
            ? data.message
            : "Invoice task sent successfully.",
        });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  handleRefundPaymentClick = (isCancel) => {
    const { invoiceDetails } = this.props;
    let props = {
      invoiceDetails: invoiceDetails,
      reloadData: this.props.reloadData,
    };
    let modal = isCancel ? "CANCEL_REFUND_PAYMENT" : "REFUND_PAYMENT";
    this.props.updateModalState(true, modal, props);
  };
  // closeReversed=()=>{

  // }

  getCreditMemoDetails = () => {
    this.props.reloadData();
  };

  handleReverseCreditClick = (isCancel) => {
    const { invoiceDetails } = this.props;
    let props = {
      invoiceDetails: invoiceDetails,
      reloadData: this.props.reloadData,
      user: this.props.user,
      history: this.props.history,
      getCreditMemoDetails: this.getCreditMemoDetails,
    };
    if (invoiceDetails.payment_history.length) {
      invoiceDetails.payment_history.map((item) => {
        if (item.payment_type == "Credit Memo" && item.amount !== null) {
          // let modal = isCancel
          //   ? "CANCEL_Invoice_Credit"
          //   : "Crdit_Invoice_Cancle";
          let modal = "CANCEL_Invoice_Credit";
          this.props.updateModalState(true, modal, props);
        } else {
          let modal = "NO_Credit_Memo";
          this.props.updateModalState(true, modal, props);
        }
        //  else {
        //   let modal = "NO_Credit_Memo";
        //   this.props.updateModalState(true, modal, props);
        // }
      });
    } else if (invoiceDetails.payment_history.length == 0) {
      let modal = "NO_Credit_Memo";
      this.props.updateModalState(true, modal, props);
    }
  };

  addNewwNote = () => {
    let optionalProps = {
      action: "AddTaskNote",
      handleAddTaskNote: this.handleAddTaskNote,
    };
    this.props.updateModalState(true, "ACCEPT_REJECT_NOTES", optionalProps);
  };

  checkBalance = () => {
    let { invoiceDetails } = this.props;
    let rowData = invoiceDetails;
    if (rowData) {
      let adjustment = rowData.adjustment ? rowData.adjustment : 0;
      if (
        (rowData.balance > 0 && adjustment == 0) ||
        (adjustment > 0 && rowData.balance != adjustment)
      ) {
        return false;
      } else if (
        (rowData.balance == 0 && adjustment == 0) ||
        (adjustment > 0 && adjustment == rowData.balance)
      ) {
        return true;
      } else return true;
    }
  };

  getDate = (date) => {
    if (date) {
      let newDate = moment.utc(date).format("MM/DD/YYYY HH:mm:ss").split(" ");
      return (
        <div>
          {newDate[0]}
          <br />
          <div style={{}}>{`${newDate[1]}`}</div>
        </div>
      );
    }
    return "";
  };

  getIcon = (row) => {
    if (row.task_id || (row.task_type && row.task_type === "invoice")) {
      return (
        <icon>
          <img src={invoiceIcon} height='100%' width='100%' />
        </icon>
      );
    } else if (
      row.payment_id ||
      (row.task_type && row.task_type === "payment")
    ) {
      return (
        <icon>
          <img src={paymentIcon} height='100%' width='100%' />
        </icon>
      );
    } else {
      return (
        <icon>
          <img src={commIcon} height='100%' width='100%' />
        </icon>
      );
    }
  };

  getObjectListData = (data, type) => {
    let headings = [],
      columns = [];
    headings =
      type == "note"
        ? [
            {
              name: "Name",
              style: {
                width: "25%",
                display: "flex",
                justifyContent: "flex-start",
              },
            },
            {
              name: "Date",
              style: {
                width: "15%",
                display: "flex",
                justifyContent: "flex-start",
              },
            },
            {
              name: "Note",
              style: {
                width: "60%",
                display: "flex",
                justifyContent: "center",
              },
            },
          ]
        : [
            {
              name: "Task Name",
              style: {
                width: "30%",
                display: "flex",
                justifyContent: "flex-start",
              },
            },
            {
              name: "Status",
              style: {
                width: "20%",
                display: "flex",
                justifyContent: "center",
              },
            },
            {
              name: "Due Date",
              style: {
                width: "10%",
                display: "flex",
                justifyContent: "center",
              },
            },
            {
              name: "Start Date",
              style: {
                width: "20%",
                display: "flex",
                justifyContent: "center",
              },
            },
            {
              name: "Assigned To",
              style: {
                width: "20%",
                display: "flex",
                justifyContent: "center",
              },
            },
            {
              name: "Complete Date",
              style: {
                width: "20%",
                display: "flex",
                justifyContent: "center",
              },
            },
            {
              name: "expander",
              style: {
                width: "3%",
                display: "flex",
                justifyContent: "center",
              },
            },
          ];

    columns = data.map((row, i) => {
      return type == "note"
        ? {
            emptyPrimary: true,
            secondaryColumns: [
              {
                key: "Name",
                title: row.display_name
                  ? row.display_name
                  : row.first_name + " " + row.last_name,
                style: {
                  width: "25%",
                  display: "flex",
                  justifyContent: "flex-start",
                },
              },
              {
                key: "Date",
                title: this.getDate(row.created_at),
                style: {
                  width: "15%",
                  display: "flex",
                  justifyContent: "flex-start",
                },
              },
              {
                key: "Note",
                title: row.note,
                style: {
                  width: "60%",
                  display: "flex",
                  justifyContent: "center",
                },
              },
            ],
          }
        : {
            emptyPrimary: true,
            row: row,
            icon: this.getIcon(row, i),
            secondaryColumns: [
              {
                key: "Task Name",
                title: row.name
                  ? row.name
                  : row.payment_id
                  ? row.is_refund
                    ? "Payment Refunded"
                    : "Payment Processed"
                  : "",
                style: {
                  width: "25%",
                  display: "flex",
                  justifyContent: "flex-start",
                },
              },
              {
                key: "Status",
                title: row.status
                  ? row.status === "Not_Started"
                    ? "Not Started"
                    : row.status
                  : "",
                style: {
                  width: "20%",
                  display: "flex",
                  justifyContent: "center",
                },
              },
              {
                key: "Due Date",
                title: row.payment_id
                  ? ""
                  : row.date
                  ? moment(row.date).format("MM/DD/YYYY")
                  : "",
                style: {
                  width: "10%",
                  display: "flex",
                  justifyContent: "center",
                },
              },
              {
                key: "Start Date",
                title: row.start_date
                  ? moment(row.start_date).format("MM/DD/YYYY")
                  : "",
                style: {
                  width: "20%",
                  display: "flex",
                  justifyContent: "center",
                },
              },
              {
                name: "Assigned To",
                title:
                  "auto_send" in row ? (row.auto_send ? "System" : "User") : "",
                style: {
                  width: "20%",
                  display: "flex",
                  justifyContent: "center",
                },
              },
              {
                key: "Complete Date",
                title: row.complete_date
                  ? moment(row.complete_date).format("MM/DD/YYYY")
                  : row.payment_id
                  ? moment(row.date).format("MM/DD/YYYY")
                  : "",
                style: {
                  width: "20%",
                  display: "flex",
                  justifyContent: "center",
                },
              },
              {
                rowType:
                  row.payment_id ||
                  (row.behavior == "accept-payment" && this.checkBalance())
                    ? "hide-task"
                    : "arrow-click",
                optionalClasses: "expander",
              },
            ],
          };
    });

    return {
      headings,
      columns,
    };
  };

  handleFilterChange = (filterBy) => {
    if (filterBy === "invoice") {
      this.setState({ invoiceFilterBy: "All" });
    } else if (filterBy === "all") {
      this.setState({ invoiceFilterBy: "All" });
    } else if (filterBy === "PastDue") {
      this.setState({ invoiceFilterBy: "All" });
    } else if (filterBy === "comm") {
      this.setState({ invoiceFilterBy: "All" });
    } else if (filterBy === "payment") {
      this.setState({ invoiceFilterBy: "All" });
    }
    this.setState({ filterBy }, () => {
      this.combileTasksAndPayments();
    });
  };

  renderLatestPaymentDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  setEmailData = (data) => {
    let headings = [],
      columns = [];
    headings = [
      "Date & Time",
      "Receiver Email",
      "Template Name",
      "Status",
      "Open Count",
      "Click Count",
      "expander",
    ];

    columns = data.map((row) => {
      let statusPillClass = null;
      if (row.status == "sent") {
        statusPillClass = "success-message";
      } else {
        statusPillClass = "fail-message";
      }

      return {
        primaryColumn: {
          key: "Date & Time",
          title: row.created_at
            ? moment.utc(row.created_at).format("MM/DD/YYYY")
            : "n/a",
          secondaryInfo: row.created_at
            ? moment.utc(row.created_at).format("HH:mm:ss")
            : "n/a",
        },
        primaryRow: {
          key: "Date & Time",
          title: row.date
            ? moment(row.date * 1000).format("MM/DD/YYYY")
            : "n/a",
          secondaryInfo: row.date
            ? moment(row.date * 1000).format("HH:mm:ss")
            : "n/a",
        },
        secondaryColumns: [
          {
            key: "Receiver Email",
            title: row.receiver_email ? row.receiver_email : "n/a",
          },
          {
            key: "Template Name",
            title: row.template_name ? row.template_name : "n/a",
          },
          {
            key: "Status",
            title: row.status ? row.status.toUpperCase() : row.status,
            optionalClasses: `${statusPillClass ? statusPillClass : ""}`,
          },
          {
            key: "Open Count",
            title: row.open_count ? row.open_count : "0",
          },
          {
            key: "Click Count",
            title: row.click_count ? row.click_count : "0",
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
            icon: "eye",
          },
        ],
        rowType: "view",
        showDetails: true,
        row: row,
      };
    });
    return {
      headings,
      columns,
    };
  };
  setLetterData = (data) => {
    let headings = [],
      columns = [];
    headings = ["Send Date", "Ex.Delivery Date", "expander"];

    columns = data.map((row) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Send Date",
            title: row.send_date
              ? this.renderLatestPaymentDate(row.send_date)
              : "n/a",
          },
          {
            key: "Ex.Delivery Date",
            title: row.expected_delivery_date
              ? this.renderLatestPaymentDate(row.expected_delivery_date)
              : "n/a",
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
            icon: "eye",
          },
        ],
        imageURL: `${row.thumbnail ? row.thumbnail : ""}`,
      };
    });
    return {
      headings,
      columns,
    };
  };

  setCommunicationData = (notes, emails, letters) => {
    this.setState({
      emailData: emails,
      letterData: letters,
      notes: notes,
    });
  };

  renderEmpty = (msg) => {
    return (
      <div
        className='admin-admins-empty-state'
        style={{ padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF" }}
      >
        <p>{msg}</p>
      </div>
    );
  };

  //calls when invoice dropdown option clicked
  handleInvoiceFilterClick = (event, type) => {
    event.preventDefault();
    this.setState({ invoiceFilterBy: type }, () => {
      this.combileTasksAndPayments();
    });
  };

  handleCommunicationFilterChange = (type) => {
    this.setState({
      selectedTaskType: type,
    });
  };

  handleLetterRowClick(event) {
    let props = {
      image: event.imageURL,
    };
    this.props.updateModalState(true, "SHOW_IMAGE", props);
  }

  handleEmailRowClick = (mailInfo) => {
    let props = {
      mailInfo: mailInfo.row.mandrill_json,
    };
    this.props.updateModalState(true, "SHOW_MAIL_DESC", props);
  };

  renderNotesCommunication = (notes) => {
    return notes && notes.length ? (
      <div>
        <ClientsCardView data={this.getObjectListData(notes, "note")} />
        <InvoiceObjectList
          data={this.getObjectListData(notes, "note")}
          hideForMobile={true}
          optionalTableClasses='in-task'
        />
      </div>
    ) : (
      this.renderEmpty(`There are currently no notes to review.`)
    );
  };

  renderLetterCommunication = (letters) => {
    let isSmallDevice = window.innerWidth <= 680 ? true : false;

    return letters && letters.length ? (
      <div>
        {isSmallDevice ? (
          <div className='big-device-grad invoice-line'>
            <div className='scrollbar in-sc'>
              <ClientsCardView
                data={this.setLetterData(letters)}
                rowActionClick={(event) => this.handleLetterRowClick(event)}
              />
            </div>
          </div>
        ) : (
          <div style={{ marginBottom: "1em" }}>
            <HistoryObjectList
              data={this.setLetterData(letters)}
              rowActionClick={(event) => this.handleLetterRowClick(event)}
              hideForMobile={true}
              optionalClasses='scrollbar'
            />
          </div>
        )}
      </div>
    ) : (
      this.renderEmpty(`There are currently no letters to review.`)
    );
  };

  renderEmailCommunication = (emailData) => {
    let isSmallDevice = window.innerWidth <= 680 ? true : false;

    return emailData && emailData.length ? (
      <div>
        {isSmallDevice ? (
          <div className='big-device-grad invoice-line'>
            <div className='scrollbar in-sc'>
              <ClientsCardView
                data={this.setEmailData(emailData)}
                rowActionClick={(event) => this.handleEmailRowClick(event)}
              />
            </div>
          </div>
        ) : (
          <div style={{ marginBottom: "1em" }}>
            <HistoryObjectList
              data={this.setEmailData(emailData)}
              rowActionClick={(event) => this.handleEmailRowClick(event)}
              hideForMobile={true}
              optionalClasses='scrollbar'
            />
          </div>
        )}
      </div>
    ) : (
      this.renderEmpty(`There are currently no emails to review.`)
    );
  };

  renderCommunicationData = (communicationType) => {
    const { invoiceDetails } = this.props;
    let { notes } = invoiceDetails;
    let { emailData, letterData } = this.state;

    switch (communicationType) {
      case "Emails":
        return this.renderEmailCommunication(emailData);
      case "Letters":
        return this.renderLetterCommunication(letterData);
      case "Internal Notes":
        return this.renderNotesCommunication(notes);
      default:
        return this.renderEmpty("No Communications available");
    }
  };

  renderNewCommunicationButton = (communicationType) => {
    const { invoiceDetails } = this.props;
    const { user } = invoiceDetails;
    let consumerId = user.user_id ? user.user_id : "";

    switch (communicationType) {
      case "Emails":
        return (
          <button
            style={{ padding: "1em 1em" }}
            onClick={() => this.sendEmail()}
          >
            New Email
          </button>
        );
      case "Letters":
        return (
          <button
            style={{ padding: "1em 1em" }}
            onClick={() => this.sendLetter()}
          >
            New Letter
          </button>
        );
      case "Internal Notes":
        return (
          <button
            style={{ padding: "1em 1em" }}
            onClick={() => this.addNewwNote()}
          >
            New Note
          </button>
        );
      default:
        return this.renderEmpty("No Communications available");
    }
  };

  render() {
    let { filterBy, tasksData, selectedTaskType } = this.state;
    const { invoiceDetails } = this.props;
    let { notes } = invoiceDetails;

    return (
      <div>
        <div
          className='task-co-fl invoice-task-tab-wrapper'
          style={{ justifyContent: "space-between" }}
        >
          <div className='task-co-fl' style={{ margin: "1em 0em 0em 0em" }}>
            <div className='he-hed he-sec-hed'>Tasks</div>
            <button
              title='All'
              className={`task-filter-btns all bt-sp-l${
                filterBy === "all" ? " active" : ""
              }`}
              onClick={() => this.handleFilterChange("all")}
            >
              All
            </button>
            <button
              title='Communication'
              className={`task-filter-btns comm${
                filterBy === "comm" ? " active" : ""
              }`}
              onClick={() => this.handleFilterChange("comm")}
            />
            <button
              title='Invoice'
              className={`task-filter-btns inv bt-sp-l${
                filterBy === "invoice" ? " active" : ""
              }`}
              onClick={() => this.handleFilterChange("invoice")}
            />
            <button
              title='Payment'
              className={`task-filter-btns pay${
                filterBy === "payment" ? " active" : ""
              }`}
              onClick={() => this.handleFilterChange("payment")}
            />
            <button
              title='Past Due'
              className={`task-filter-btns all ${
                this.state.pastDueCount > 0 ? "red-past-due" : ""
              } bt-sp-l${filterBy === "PastDue" ? " active" : ""}`}
              style={{ marginLeft: "1rem" }}
              onClick={() => this.handleFilterChange("PastDue")}
            >
              {this.state.pastDueCount}
            </button>
            <div style={{ marginRight: "1rem" }}>
              {invoiceDetails.is_eligible_for_placement &&
                !invoiceDetails.is_placed_collections && (
                  <img
                    src={PlacementEligibleIcon}
                    width={"50px"}
                    height={"50px"}
                  />
                )}
              {invoiceDetails.is_eligible_for_placement &&
                invoiceDetails.is_placed_collections && (
                  <img src={LockIcon} width={"50px"} height={"50px"} />
                )}
            </div>
          </div>
          {this.state.filterBy !== "PastDue" ? (
            <div
              className='add-account-invoice filter-by-task-status'
              style={{ margin: "1em 0em 0em 0em" }}
            >
              <DropdownButton
                title={`Status: ${this.state.invoiceFilterBy}`}
                // primaryLink=""
                overrideStyle={true}
                forFilter={true}
                squareBorder
                clientMobile={true}
                totalFilter={true}
                onPrimaryClick={(event) => {}}
              >
                <MenuItem
                  handleClick={(event) =>
                    this.handleInvoiceFilterClick(event, "Pending")
                  }
                  optionalClasses={
                    this.state.invoiceFilterBy === "Pending"
                      ? " filter-select"
                      : " "
                  }
                >
                  Pending
                </MenuItem>
                <MenuItem
                  handleClick={(event) =>
                    this.handleInvoiceFilterClick(event, "Completed")
                  }
                  optionalClasses={
                    this.state.invoiceFilterBy === "Completed"
                      ? " filter-select"
                      : " "
                  }
                >
                  Completed
                </MenuItem>
                <MenuItem
                  handleClick={(event) =>
                    this.handleInvoiceFilterClick(event, "All")
                  }
                  optionalClasses={
                    this.state.invoiceFilterBy === "All"
                      ? " filter-select"
                      : " "
                  }
                >
                  All
                </MenuItem>
              </DropdownButton>
            </div>
          ) : null}
        </div>

        {tasksData && tasksData.length ? (
          <div
            className='scrollbar'
            style={{ maxHeight: "30em", overflowY: "auto", marginTop: "0.5em" }}
          >
            {/* cardview for mobile device */}
            <ClientsCardView
              data={this.getObjectListData(tasksData)}
              rowActionClick={(event) => this.rowExpanderClick(event)}
            />
            {/* list view for big devices */}
            <InvoiceObjectList
              data={this.getObjectListData(tasksData)}
              hideForMobile={true}
              isBeforeIcon={true}
              rowActionClick={(event) => this.rowExpanderClick(event)}
              optionalTableClasses='in-task-list '
              optionalClasses='scrollbar in-sc'
            />
          </div>
        ) : (
          this.renderEmpty("No tasks available")
        )}
        <div
          className='task-co-fl'
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className='comm-notes-section'>
            <div className='he-hed he-sec-hed' style={{ whiteSpace: "nowrap" }}>
              Communications & Notes
            </div>
            <div className='flex'>
              <div className='comm-notes-btn-container'>
                <button
                  title={"Internal Notes"}
                  className={`task-filter-btns note comm-btns ${
                    selectedTaskType === "Internal Notes" ? "active" : ""
                  }`}
                  onClick={() =>
                    this.handleCommunicationFilterChange("Internal Notes")
                  }
                />
                <button
                  title={"Emails"}
                  className={`task-filter-btns email comm-btns ${
                    selectedTaskType === "Emails" ? " active" : ""
                  }`}
                  onClick={() => this.handleCommunicationFilterChange("Emails")}
                />
                <button
                  title={"Letters"}
                  className={`task-filter-btns comm-btns letter ${
                    selectedTaskType === "Letters" ? " active" : ""
                  }`}
                  onClick={() =>
                    this.handleCommunicationFilterChange("Letters")
                  }
                />
              </div>
              <div className='in-new-btn'>
                {this.renderNewCommunicationButton(selectedTaskType)}
              </div>
            </div>
          </div>
        </div>
        {this.renderCommunicationData(selectedTaskType)}
      </div>
    );
  }
}

export default InvoiceTasks;
