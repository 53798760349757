import React, { Component } from "react";
import { Table } from "reactstrap";
import shortid from "shortid";
import moment from "moment";
import styles from "../../CustomTable/DynamicTable.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";

const tempStyle = {
  headerTile: {
    display: "flex",
    alignItems: "center",
  },
};

class InvoiceObjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  checkPrimaryColor = (props) => {
    return props.hasOwnProperty("appThemeData")
      ? props.appThemeData.hasOwnProperty("primary_color")
        ? props.appThemeData.primary_color
        : "#0ab894"
      : "#0ab894";
  };

  handleExpanderClick = (event, rowData) => {
    event.preventDefault();
    this.props.rowActionClick(rowData);
  };

  showOnlyHeader = (header) => {
    return (
      header === "Paid Amount" ||
      header === "Discount" ||
      header === "Auto Scheduled" ||
      header === ""
    );
  };

  renderRowExpander = (rowData, col) => {
    let { fromConsumer } = this.props;
    let type = col.icon;
    if (type && type === "up-down") {
      return (
        <a
          key={shortid.generate()}
          onClick={(event) => this.handleExpanderClick(event, rowData)}
        ></a>
      );
    } else if (type && type === "eye") {
      return (
        <a
          key={shortid.generate()}
          onClick={(event) => this.handleExpanderClick(event, rowData)}
        >
          <svg>
            <use xlinkHref='#view' />
          </svg>
        </a>
      );
    } else {
      if (rowData.rowData === "static") {
        return (
          <a
            key={shortid.generate()}
            onClick={(event) => this.handleExpanderClick(event, rowData)}
          ></a>
        );
      } else {
        return (
          <td>
            <a
              key={shortid.generate()}
              className='client-caret expander-caret'
              onClick={(event) => this.handleExpanderClick(event, rowData)}
              style={{
                margin: "0.5rem 0",
                display: "flex",
                borderTop: `0.8em solid ${this.checkPrimaryColor(this.props)}`,
              }}
            ></a>
          </td>
        );
      }
    }
  };

  renderPrimaryColumn = (primaryColumn) => {
    let primaryContent = <div>{primaryColumn.title}</div>;

    let secondaryContent = primaryColumn.secondaryInfo ? (
      <div>{primaryColumn.secondaryInfo}</div>
    ) : null;

    return (
      <div className={styles.rowData}>
        {primaryContent}
        {secondaryContent}
      </div>
    );
  };

  showSortContent = (header, index) => {
    const { sortHeaders } = this.props;
    if (header === sortHeaders[0]) {
      return (
        <div
          className='caret-header-title'
          onClick={() => this.props.sortInvoiceData(header, !sortHeaders[1])}
        >
          <div style={{ display: "flex" }}>
            <div className={styles.headingStyle}>{header}</div>
            {!this.props.hasOwnProperty("hideSort") && (
              <div
                style={tempStyle.headerTile}
                id='table-header-caret'
                className={`client-caret${
                  sortHeaders[1] ? " client-caret-up" : ""
                } `}
              />
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div onClick={() => this.props.sortInvoiceData(header, true)}>
          <div style={tempStyle.headerTile} className={styles.headingStyle}>
            {header}
            {header !== "Action" && !this.props.hasOwnProperty("hideSort") && (
              <div
                id='table-header-caret'
                className={`client-caret`}
                style={{ marginLeft: "0.4em" }}
              />
            )}
          </div>
        </div>
      );
    }
  };

  render() {
    let {
      data,
      isStatus,
      isClose,
      isBeforeIcon,
      optionalTableClasses,
      scrollRef,
      sortHeaders,
      isPrimaryColumn,
      isPaymentPlansData,
      hideForMobile,
      memoView,
      isBordered,
      arAgingData,
    } = this.props;

    return (
      <div ref={scrollRef} className={memoView ? "" : "scrollbar in-sc"}>
        <Table hover striped className={!arAgingData && "mobile-hide-list"}>
          <thead className={styles.headerFix}>
            <tr>
              {data.headings &&
                data.headings.map((header) => {
                  return (
                    <th
                      key={shortid.generate()}
                      style={{ backgroundColor: "#DADADA", borderTop: "none" }}
                      className={styles.headingStyle}
                    >
                      {header.name === "expander"
                        ? ""
                        : sortHeaders
                        ? header.name === "expander"
                          ? null
                          : this.showOnlyHeader(header.name)
                          ? header.name
                          : this.showSortContent(header.name)
                        : header.name}
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {data.columns &&
              data.columns.map((item) => {
                return (
                  <tr key={shortid.generate()} className={styles.rowData}>
                    {item.primaryColumn && (
                      <td key={shortid.generate()} className={styles.rowData}>
                        {this.renderPrimaryColumn(item.primaryColumn)}
                      </td>
                    )}
                    {item.secondaryColumns &&
                      item.secondaryColumns.map((col) => {
                        if (col.rowType && col.rowType === "arrow-click") {
                          return this.renderRowExpander(item, col);
                        } else if (col.rowType && col.rowType === "hide-task") {
                          return <div />;
                        }
                        return (
                          <td
                            className={`${styles.rowData} ${
                              col.key === "Action" && styles.nowrap
                            }`}
                            key={shortid.generate()}
                            onClick={() =>
                              arAgingData &&
                              col.key === "Account" &&
                              this.props.history.push(
                                `/client/accounts/${col.Id}`
                              )
                            }
                            style={{
                              cursor:
                                arAgingData &&
                                col.key === "Account" &&
                                "pointer",
                            }}
                          >
                            {col.key === "Inv. #" ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {isStatus ? (
                                  <div
                                    className={`payment-status ${
                                      isClose || item.taskStatus
                                        ? "col3"
                                        : item.status
                                        ? "col1"
                                        : "col2"
                                    }`}
                                  />
                                ) : isBeforeIcon ? (
                                  item.icon
                                ) : (
                                  ""
                                )}
                                <div style={{ marginLeft: "0.2rem" }}>
                                  {col.title}
                                </div>
                              </div>
                            ) : (
                              col.title
                            )}
                          </td>
                        );
                      })}
                  </tr>
                );
              })}
            {arAgingData && (
              <tr
                style={{
                  backgroundColor: "#008BB2",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#FFF",
                  whiteSpace: "nowrap",
                }}
                className={styles.footerFix}
              >
                <td>{arAgingData.rowHead}</td>
                <td>{arAgingData.openInvoices}</td>
                <td>{arAgingData.total}</td>
                <td>{arAgingData.currentBalnc}</td>
                <td>{arAgingData.report1to30}</td>
                <td>{arAgingData.report30to60}</td>
                <td>{arAgingData.report60to90}</td>
                <td>{arAgingData.report90to120}</td>
                <td>{arAgingData.report121plus}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default InvoiceObjectList;
