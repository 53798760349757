import React, { Component } from "react";

class ToggleSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { isOn, isOnOpacityLess, flexStart, id, alignLeft, customStyle } =
      this.props;
    let isDisable = false;
    if (this.props.isDisable) {
      isDisable = true;
    }
    return (
      <div
        style={{
          marginTop: 2,
          cursor: isDisable ? "none" : "pointer",
          pointerEvents: isDisable ? "none" : "pointer",
          display: "grid",
          justifyContent: flexStart ? "start" : alignLeft ? "left" : "center",
          justifyItems: "center",
          alignItems: "center",
          ...customStyle,
        }}
        onClick={(event) =>
          !isDisable && this.props.toggleButton
            ? this.props.toggleButton(event)
            : ""
        }
      >
        <span
          className='container'
          style={{
            position: "relative",
            display: "inline-block",
            width: "40px",
            height: "16px",
            backgroundColor: "transparent",
          }}
        >
          <button
            type='checkbox'
            disabled={isDisable}
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "transparent",
            }}
            id={id}
          />
          <span
            className='switch'
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
              pointerEvents: "none",
            }}
          >
            <span
              className='track'
              style={{
                flex: 1,
                height: "100%",
                borderRadius: "8px",
                background: isOn ? "#0ab894" : "#c2c2c2",
                opacity: isOnOpacityLess ? "0.6" : "1",
              }}
            />
            <span
              className='slider'
              style={{
                position: "absolute",
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                background: "white",
                transition: "transform 0.5s",
                transform: isOn ? "translateX(26px)" : "translateX(2px)",
              }}
            />
          </span>
        </span>
      </div>
    );
  }
}

export default ToggleSwitch;
