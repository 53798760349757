import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import "react-select/dist/react-select.css";
import Select from "react-select/lib/Select";
import downloadImg from "../../../../assets/icons/info_teal.png";
import AppCanvasLoader from "../../../../components/App/AppCanvasLoader";
import MaskedTextInput from "react-text-mask";
// API
import * as API from "../../../../utils/api";
import {
  convertCreditorsToArrayForSelectComponent,
  isValidDate,
  showToast,
  validateEmail,
} from "../../../../utils/helpers";
import ToggleSwitch from "../../../ToggleSwitch";
// Components
import Modal from "../Modal";
import TeamMemberContactPreferences from "./TeamMemberContactPreferences";

class AddTeamMembersModal extends Component {
  state = {
    input: {
      save_and_send: true,
      adminInformation: {
        first_name: {
          value: "",
          hasError: false,
          isRequired: true,
        },

        last_name: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        email: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        phone_number: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        client_role: {
          value: "",
        },
      },
      is_all_client: true,
      ccPref: "All Clients",
      contact_preferences: [],
    },
    inputs: {
      auto_send: false,
      auto_send_on_import: false,
      delivery_method: "Email & Letter",
      internal: false,
      is_all_client: false,
      name: "",
      reminder_schedule: 0,
      contact_count: 0,
      state: false,
      ccPref: "All Clients",
      document_type_ids: [],
    },
    showPrefPopUp: false,
    ccOptions: [
      {
        label: "All Clients",
        value: "All Clients",
        flag: true,
      },
      {
        label: "Selected",
        value: "Selected",
        flag: false,
      },
    ],
    delivery_Categories: [],
    state: false,
    slide: 1,
    allow_to_make_offer: false,
    view_all_accounts: false,
    isLoading: false,
    is_internal_contact: false,
    user: "User",
    selected_contacts: [],
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    employees: [],
    oldEmployees: [],
    is_assigned: "",
  };

  componentDidMount() {
    let { delivery_Categories } = this.state;
    let { optionalProps } = this.props;
    if (optionalProps && optionalProps.item) {
      if (optionalProps.item.is_all_client) {
        optionalProps.item.ccPref = "All Clients";
      } else {
        optionalProps.item.ccPref = "Selected";
      }
      if (!optionalProps.item.state) {
        optionalProps.item.auto_send = false;
        optionalProps.item.auto_send_on_import = false;
        optionalProps.item.internal = false;
        optionalProps.item.is_all_client = false;
        optionalProps.item.ccPref = "All Clients";
        optionalProps.item.delivery_method = "Email & Letter";
      }
      this.setState(
        (state) => ({
          ...state,
          inputs: optionalProps.item,
          documentType: this.state.documentType,
        }),
        () => {}
      );
    }
    if (optionalProps.data) {
      this.assignedConstactsApi(optionalProps.data.data.id);
      this.setState((state) => ({
        ...state,
        input: {
          adminInformation: {
            first_name: {
              value: optionalProps.data.data.first_name,
              hasError: false,
              isRequired: true,
            },
            last_name: {
              value: optionalProps.data.data.last_name,
              hasError: false,
              isRequired: true,
            },
            email: {
              value: optionalProps.data.data.email,
              hasError: false,
              isRequired: true,
            },
            client_role: {
              value: optionalProps.data.data.role,
            },
          },
        },
      }));
      this.state.user = optionalProps.data.data.role;
      this.state.is_internal_contact =
        optionalProps.data.data.is_internal_contact;
    } else {
      API.getSelectedContactsunassigned(this.props.user).then((data) => {
        if (data) {
          this.setState({
            employees: data.data,
          });
        }
      });
    }
  }
  assignedConstactsApi = (rowData) => {
    this.setState({ isLoading: true });
    API.assignedConstactsApi(this.props.user, rowData)
      .then((data) => {
        if (data) {
          const { data: employees, is_all_client } = data;

          this.setState((state) => ({
            employees,
            oldEmployees: employees,
            input: {
              ...state.input,
              is_all_client: is_all_client || state.input.is_all_client,
            },
          }));

          const assignedEmployees = employees.filter((emp) => emp.is_assigned);
          const selectedContacts = assignedEmployees.map((emp) => emp.user_id);

          this.setState({
            is_assigned: assignedEmployees.length,
            selected_contacts: [
              ...this.state.selected_contacts,
              ...selectedContacts,
            ],
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        console.error("Error in assignedConstacts Api:", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  setSelectData = (data) => {
    if (data && data.length) {
      data.map((i, n) => {
        i.label = i.name;
        i.value = i.name;
      });
    }
    this.setState({ docTypeList: data });
  };
  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
    this.state.input.is_all_client = newPartialInput.is_all_client;
  }
  openCotactPref = (checklist) => {
    if (checklist) {
      this.state.input.is_all_client = false;
      this.state.input.contact_preferences = checklist;
      this.state.input.contact_count = checklist.length;
    }
    this.setState({
      showPrefPopUp: !this.state.showPrefPopUp,
      oldEmployees: this.state.employees,
      input: this.state.input,
    });
  };
  handleModalClose = () => {
    this.props.hideModal();
    this.props.history.push("/admin/accounts");
  };

  handleAdminInfoInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        adminInformation: {
          ...state.input.adminInformation,
          ...newPartialInput,
        },
      },
    }));
  }
  updateEmployee = (
    user,
    adminForSubmission,
    consumer_ids,
    is_all_client,
    save_and_send
  ) => {
    let { optionalProps } = this.props;
    API.updateEmployee(
      user,
      adminForSubmission,
      consumer_ids,
      is_all_client,
      save_and_send
    ).then((data) => {
      this.scrollToTop();
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (data.status_code === 400) {
          this.props.updateModalState(true, "ERROR", { message: data.message });
        } else {
          let optionalProps = {
            account: adminForSubmission,
          };
          // this.props.updateModalState(true, 'ACCOUNT_UPDATED', optionalProps);
          this.props.updateModalState(true, "SUCCESS", {
            message: "Team member updated successfully.",
          });
          this.props.optionalProps &&
            this.props.optionalProps.reloadData() &&
            this.props.optionalProps.reloadData();
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };
  handleSubmit = (event, save_and_sends) => {
    event.preventDefault();
    const { input, inputs } = this.state;
    const { optionalProps } = this.props;
    if (this.validateForm()) {
      event.currentTarget.disabled = true;
      let is_all_client = this.state.input.is_all_client,
        save_and_send = save_and_sends ? save_and_sends : false;
      let consumer_ids =
        input.contact_preferences != undefined
          ? input.contact_preferences
          : this.state.selected_contacts;
      let adminForSubmission = {
        first_name: input.adminInformation.first_name.value,
        last_name: input.adminInformation.last_name.value,
        email: input.adminInformation.email.value,
        is_internal_contact: this.state.is_internal_contact,
        role: this.state.user,
        id: optionalProps.data ? optionalProps.data.data.id : null,
      };
      optionalProps.data
        ? this.updateEmployee(
            this.props.user,
            adminForSubmission,
            consumer_ids,
            is_all_client,
            save_and_send
          )
        : API.createEmployee(
            this.props.user,
            adminForSubmission,
            consumer_ids,
            is_all_client,
            save_and_send
          ).then((data) => {
            this.scrollToTop();
            if ((typeof data != "undefined" || data != null) && !data.error) {
              if (data.status_code === 400) {
                this.props.updateModalState(true, "ERROR", {
                  message: data.message,
                });
              } else {
                let optionalProps = {
                  account: adminForSubmission,
                };
                // this.props.updateModalState(true, 'ACCOUNT_CREATED', optionalProps);
                this.props.updateModalState(true, "SUCCESS", {
                  message: "Team member added successfully.",
                });
                this.props.optionalProps &&
                  this.props.optionalProps.reloadData() &&
                  this.props.optionalProps.reloadData();
              }
            } else {
              this.setState((state) => ({
                ...state,
                error: {
                  hasAnyError: true,
                  statusCode: data
                    ? data.status ||
                      (data.message && data.message == "Access denied."
                        ? 401
                        : 500) ||
                      500
                    : 500,
                },
              }));
            }
          });
    } else {
      this.setFormErrorState();
    }
  };

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  validateForm = () => {
    let errorsArePresent = false;
    let inputs = { ...this.state.input.adminInformation };
    // Check inputs for errors
    Object.entries(inputs).forEach(([key, value]) => {
      if (key === "email") {
        if (
          !value.value ||
          (!validateEmail(value.value) && value.value !== "")
        ) {
          errorsArePresent = true;
        }
      } else if (key === "first_name") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "last_name") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      }
    });
    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  };

  setFormErrorState = () => {
    let errorsArePresent = false;
    let newInputState = { ...this.state.input.adminInformation };
    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "email") {
        if (
          !value.value ||
          (!validateEmail(value.value) && value.value !== "")
        ) {
          newInputState[key].hasError = true;
        }
      } else if (key === "last_name") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "first_name") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (value.isRequired && value.value === "") {
        newInputState[key].hasError = true;
      }

      if (newInputState[key].hasError) {
        errorsArePresent = true;
      }
    });

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: {
          ...state.input,
          adminInformation: newInputState,
        },
      }));
    }
  };
  handleToggle = (e, type) => {
    e.preventDefault();
    this.setState({ [type]: !this.state[type] });
  };
  checkUser = (type) => {
    if (type === "Admin") {
      this.setState((state) => ({
        ...state,
        user: "Admin",
      }));
    } else if (type === "User") {
      this.setState((state) => ({
        ...state,
        user: "User",
      }));
    }
  };
  renderErrorMessage(input) {
    let message = "";

    if (input === "first_name") {
      message = "Please enter first name";
    } else if (input === "last_name") {
      message = "Please enter last name";
    } else if (input === "email") {
      message = "Please enter a valid email address";
    } else {
      message = "Please complete this field";
    }
    return <div className='input-error-message'>{message}</div>;
  }
  infotitle = (header) => {
    let title = null;
    header === "Role"
      ? (title =
          "Tip: Standard Users have access to everything but your company’s configuration settings. Admins have access to everything.")
      : (title =
          "Tip: Internal Contacts will receive communications sent to All Accounts or Selected Accounts.");
    return (
      <>
        <span style={{ fontWeight: "bold", fontFamily: "unset" }}>
          {header}
        </span>
        <span title={title} style={{ marginLeft: "5px" }}>
          <img src={downloadImg} width='20' height='20' />
        </span>
      </>
    );
  };

  render() {
    let { input, slide, inputs, creditors, error, showPrefPopUp, isLoading } =
      this.state;
    let { optionalProps } = this.props;
    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }
    if (showPrefPopUp) {
      return (
        <TeamMemberContactPreferences
          user={this.props.user}
          item={inputs}
          hideModal={this.openCotactPref}
          handleClose={() => {
            this.setState({ showPrefPopUp: false });
          }}
          selected_contacts={inputs.selected_contacts}
          new_selected_list={this.state.input.contact_preferences}
          employees={this.state.employees}
          contact_preferences={
            input.contact_preferences ? input.contact_preferences : []
          }
        />
      );
    }

    return (
      <Modal
        optionalClasses='add-account-modal scroll add-admin-form-width add-admin-modal'
        title={optionalProps.data ? "Edit User" : "Add Team Member"}
        titleClasses='titleSize'
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
      >
        {this.state.isLoading ? (
          <AppCanvasLoader />
        ) : (
          <form className='admin-form'>
            <header className='modal-slide-header'></header>
            <div className={`modal-slide${slide === 1 ? "" : " hide"}`}>
              <div className='form-container add-admin-form-container'>
                <div className='form-column add-admin-from-column'>
                  <div className={`input-container`}>
                    <label
                      style={{ fontWeight: "bold", fontFamily: "unset" }}
                      htmlFor='name'
                    >
                      Team Member's Name
                    </label>
                    <div
                      className='input-container'
                      style={{ display: "flex" }}
                    >
                      <div
                        className={`input-container${
                          input.adminInformation.first_name.hasError
                            ? " error"
                            : ""
                        }`}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "600px",
                        }}
                      >
                        <input
                          className='multi-input-field-input'
                          id='team_member_first_name'
                          name='name'
                          type='text'
                          placeholder='First Name'
                          value={input.adminInformation.first_name.value}
                          onChange={(event) =>
                            this.handleAdminInfoInputChange({
                              first_name: {
                                value: event.target.value,
                                hasError: false,
                              },
                            })
                          }
                        />
                        {input.adminInformation.first_name.hasError
                          ? this.renderErrorMessage("first_name")
                          : null}
                      </div>
                      <div
                        className={`input-container${
                          input.adminInformation.last_name.hasError
                            ? " error"
                            : ""
                        }`}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "600px",
                          marginLeft: "10px",
                        }}
                      >
                        <input
                          className='multi-input-field-input'
                          name='name'
                          id='team_member_last_name'
                          type='text'
                          placeholder='Last Name'
                          value={input.adminInformation.last_name.value}
                          onChange={(event) =>
                            this.handleAdminInfoInputChange({
                              last_name: {
                                value: event.target.value,
                                hasError: false,
                              },
                            })
                          }
                        />
                        {input.adminInformation.last_name.hasError
                          ? this.renderErrorMessage("last_name")
                          : null}
                      </div>
                    </div>
                  </div>

                  <div
                    className={`input-container${
                      input.adminInformation.email.hasError ? " error" : ""
                    }`}
                  >
                    <label
                      style={{ fontWeight: "bold", fontFamily: "unset" }}
                      htmlFor='email'
                    >
                      Team Member's Email Address
                    </label>
                    <input
                      name='email'
                      id='team_member_email_address'
                      type='email'
                      placeholder='address@email.com'
                      value={input.adminInformation.email.value}
                      onChange={(event) =>
                        this.handleAdminInfoInputChange({
                          email: { value: event.target.value, hasError: false },
                        })
                      }
                    />
                    {input.adminInformation.email.hasError
                      ? this.renderErrorMessage("email")
                      : null}
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginBottom: "15px",
                  marginTop: "15px",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                }}
              >
                {this.infotitle("Role")}
              </div>
              <div className='team-member-modal'>
                <input
                  name='view-account'
                  id='team_member_role_user'
                  checked={
                    input.adminInformation.client_role.value == "User"
                      ? input.adminInformation.client_role.value
                      : this.state.user === "User"
                      ? true
                      : false
                  }
                  onChange={(event) => {
                    this.checkUser("User");
                  }}
                  type='radio'
                  style={{
                    WebkitAppearance: `checkbox`,
                    width: `20px`,
                    marginRight: `10px`,
                    marginTop: `-5px`,
                  }}
                />
                <label
                  style={{
                    fontWeight: "bold",
                    fontFamily: "unset",
                    fontSize: "14px",
                  }}
                >
                  User
                </label>
              </div>
              <div className='team-member-modal'>
                <input
                  name='view-account'
                  id='team_member_role_admin'
                  checked={
                    input.adminInformation.client_role.value == "Admin"
                      ? input.adminInformation.client_role.value
                      : this.state.user === "Admin"
                      ? true
                      : false
                  }
                  onChange={(event) => {
                    this.checkUser("Admin");
                  }}
                  type='radio'
                  style={{
                    WebkitAppearance: `checkbox`,
                    width: `20px`,
                    marginRight: `10px`,
                    marginTop: `-5px`,
                  }}
                />
                <label
                  style={{
                    fontWeight: "bold",
                    fontFamily: "unset",
                    fontSize: "14px",
                  }}
                >
                  Admin
                </label>
              </div>
              <div className='team-member-modal-internal-contact'>
                <label
                  style={{
                    fontWeight: "bold",
                    fontFamily: "unset",
                    fontSize: "14px",
                  }}
                >
                  Internal Contact
                </label>
                <div className='team-member-modal-toggle'>
                  <ToggleSwitch
                    isOn={this.state.is_internal_contact}
                    id='team_member_internal_contacts_toggle'
                    toggleButton={(event) => {
                      this.handleToggle(event, "is_internal_contact");
                    }}
                  />
                  <span style={{ marginLeft: "20px", fontSize: "14px" }}>
                    Assign Contacts to Account
                  </span>
                </div>
                <div className='team-member-modal-select'>
                  <Select
                    inputProps={{
                      autoComplete: "none",
                      autoCorrect: "off",
                      spellCheck: "off",
                    }}
                    resetValue=''
                    id='team_member_select_options'
                    className='form-select'
                    name='Client Contact Preferences'
                    value={input.ccPref}
                    placeholder='All Clients'
                    options={
                      this.state.ccOptions ? this.state.ccOptions : input.ccPref
                    }
                    onChange={(event) =>
                      this.handleInputChange({
                        is_all_client: event && event.flag ? event.flag : false,
                        ccPref: event ? event.value : "",
                      })
                    }
                  />
                  <button
                    disabled={input.is_all_client ? true : false}
                    style={{ fontSize: "16px", fontWeight: "450" }}
                    className={`select-inner-btn mg-mt-0 mg-brand2-color${
                      input.is_all_client ? "" : " active"
                    }`}
                    onClick={() => this.openCotactPref()}
                    id='team_member_select_clients'
                  >
                    Select
                  </button>
                </div>
                <div style={{ marginLeft: "60px", fontSize: "14px" }}>
                  Selected Contacts:
                  {this.state.input.contact_preferences
                    ? this.state.input.contact_preferences.length
                    : this.state.input.is_all_client
                    ? "ALL"
                    : this.state.is_assigned >= 1
                    ? this.state.is_assigned
                    : this.state.selected_contacts.length
                    ? this.state.selected_contacts.length
                    : 0}
                </div>
              </div>
              <div>
                <input
                  type='submit'
                  value='Cancel'
                  id='team_member_cancel'
                  className='cta mg-brand2-color emp-add-btn-mob'
                  style={{
                    borderRadius: "10px",
                    width: "200px",
                    marginTop: "20px",
                    marginLeft: "4px",
                    fontSize: "16px",
                  }}
                  onClick={() => this.props.hideModal()}
                />
                <input
                  type='submit'
                  id='team_member_save_only'
                  value='Save Only'
                  className='cta mg-brand2-color emp-add-btn-mob'
                  style={{
                    borderRadius: "10px",
                    width: "200px",
                    marginTop: "20px",
                    marginLeft: "4px",
                    fontSize: "16px",
                  }}
                  onClick={(event) => this.handleSubmit(event)}
                />
                <input
                  type='submit'
                  id='team_member_save_and_send_invite'
                  value='Save & Send Invite'
                  className='cta mg-brand2-color emp-add-btn-mob'
                  style={{
                    borderRadius: "10px",
                    width: "200px",
                    marginTop: "20px",
                    marginLeft: "4px",
                    fontSize: "16px",
                  }}
                  onClick={(event) => this.handleSubmit(event, true)}
                />
              </div>
            </div>
          </form>
        )}
      </Modal>
    );
  }
}

export default withRouter(AddTeamMembersModal);
