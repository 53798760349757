import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
// Components
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import ToggleSwitch from "../../../components/ToggleSwitch";
// Utils
import * as API from "../../../utils/api";
import Emitter from "../../../utils/event-emitter";
import check from "../../../assets/icons/check.gif";
import checkmarkgray from "../../../assets/icons/checkmark_gray.png";
import checkmark_teal from "../../../assets/icons/checkmark_teal.png";
import Icon from "../../../assets/icons/info_teal.png";
import checkmark_red from "../../../assets/icons/checkmark_red.png";
import DisconnectedQBIcon from "../../../assets/icons/DisconnectedQBIcon.svg";
import ConnectedQBIcon from "../../../assets/icons/ConnectedQBIcon.svg";
import Modal from "../../../components/App/Modals/Modal";
import Cookies from "universal-cookie";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import styles from "../../../components/Buttons/button.module.scss";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
class QuickBookDataSyncView extends Component {
  state = {
    isConnectedToQB: true,
    isConnectedToAccountSys: true,
    OrgclientData: {},
    isLoading: false,
    Interval: "",
    timerCount: 0,
    syncDone: 0,
    lastSyncDate: "",
    SyncStatus: "",
    synced_customers: 0,
    synced_invoices: 0,
    synced_payments: 0,
    synced_terms: 0,
    synced_services: 0,
    total_services_to_sync: 0,
    total_terms_to_sync: 0,
    total_customers_to_sync: 0,
    total_invoices_to_sync: 0,
    total_payments_to_sync: 0,
    customer: 0,
    invoice: 0,
    payment: 0,
    service: 0,
    term: 0,
    isSyncOn: false,
    accountToggle: false,
    invoiceToggle: false,
    paymentToggle: false,
    autoRecToggle: false,
    accountCreateToggle: false,
    autoSyncServices: false,
    autoImportServices: false,
    autoSelectIncome: false,
    usingSalesTex: false,
    allowDiscount: false,
    autoImportTerms: false,
    autoPushTerms: false,
    inventryTracking: false,
    flag: true,
    peferenceErrorFlag: false,
    peferenceButton: false,
    isSynchFlag: true,
    isRenderQb: false,
    isQBDataSynced: false,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };
  style = {
    pText: {
      fontSize: "14px",
      color: "#2b2828",
      margin: "0px",
    },
  };
  componentDidMount = () => {
    this.getOrganizationalDetails();
    if (this.props.user && this.props.user.user_id) {
      this.checkAuthStatus();
    }
    this.props.syncDataToHeaders(this.renderSyncQB);
  };

  componentWillUnmount = () => {
    if (!this.state.isSyncOn) {
      this.dismissCall();
    }
  };

  getOrganizationalDetails = () => {
    const cookies = new Cookies();
    let authuser = cookies.get("accessToken", { path: "/", secure: true });
    let user = {
      user_auth_token: authuser,
      organization_key: this.props.orgKey,
    };
    this.setState({ isLoading: true });
    if (user && user != null && authuser != null && authuser != undefined) {
      API.getOrgDetails(user).then((data) => {
        if (data && data.status_code == 200) {
          this.setState({
            OrgclientData: data.data,
            isLoading: false,
            isConnectedToAccountSys:
              data.data.organization.qb_status &&
              data.data.organization.qb_status === "ACTIVE"
                ? true
                : false,
          });
        } else {
          this.setState({ isLoading: false });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  //stop quickbooks account syncing
  cancelQBSync = () => {
    this.setState({ isSyncOn: false });
    this.dismissCall();
  };

  //calls when QB account sync sucessfully completed
  openDashboard = () => {
    //clear all flags and redirect to dashboard
    this.cancelQBSync();
  };
  cancelSyncQBData = () => {
    this.setState({
      isSyncOn: false,
      synced_customers: 0,
      total_customers_to_sync: 0,
      synced_invoices: 0,
      synced_payments: 0,
      synced_terms: 0,
      synced_services: 0,
      total_invoices_to_sync: 0,
      total_payments_to_sync: 0,
      syncDone: 0,
      total_services_to_sync: 0,
      total_terms_to_sync: 0,
      SyncStatus: "",
    });
    this.checkAuthStatus(true);
  };
  //initialize the QB data syncing
  getQBData = () => {
    this.setState(
      {
        isSyncOn: true,
        synced_customers: 0,
        total_customers_to_sync: 0,
        synced_invoices: 0,
        synced_payments: 0,
        synced_terms: 0,
        synced_services: 0,
        total_invoices_to_sync: 0,
        total_payments_to_sync: 0,
        syncDone: 0,
        total_services_to_sync: 0,
        total_terms_to_sync: 0,
        SyncStatus: "",
      },
      () => {
        localStorage.setItem("isSyncQb", true);

        this.getBackendResponse();
      }
    );
    API.getQBCustomer(this.props.user).then((data) => {
      if (typeof data != "undefined" || data != null) {
        //success
      } else {
        // this.setState((state) => ({
        //   ...state,
        //   error: {
        //     hasAnyError: true,
        //     statusCode: data
        //       ? data.status ||
        //         (data.message && data.message == "Access denied."
        //           ? 401
        //           : 500) ||
        //         500
        //       : 500,
        //   },
        // }));
      }
    });

    this.props.syncQbIcon(true);
  };

  //show confirmation alert before sync start
  syncQBData = () => {
    let flag =
      this.state.customer > 0 ||
      this.state.invoice > 0 ||
      this.state.payment > 0
        ? true
        : false;

    let message = flag
      ? "Select 'Yes' to  synchronize QuickBooks Online data. Select 'No' to cancel."
      : "All data from QuickBooks Online is already synced. Select 'Yes' to  synchronize QuickBooks Online now. Select 'No' to cancel.";
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: message,
      confirmClick: this.getQBData,
      closeOnClickOutside: true,
    });
  };

  //clear interval
  dismissCall = () => {
    clearInterval(this.state.Interval);
    this.setState({ isLoading: false });
  };
  imgRender = (res) => {
    return res ? (
      <img
        src={checkmark_teal}
        width='35'
        height='35'
        style={{ marginLeft: "7px" }}
      />
    ) : this.state.flag ? (
      <img
        src={checkmarkgray}
        width='35'
        height='35'
        style={{ marginLeft: "7px" }}
      />
    ) : (
      <img src={check} width='35' height='35' style={{ marginLeft: "7px" }} />
    );
  };
  imgRenderError = (res) => {
    return this.state.peferenceErrorFlag && !res ? (
      <img
        src={checkmark_red}
        width='35'
        height='35'
        style={{ marginLeft: "7px" }}
      />
    ) : res ? (
      <img
        src={checkmark_teal}
        width='35'
        height='35'
        style={{ marginLeft: "7px" }}
      />
    ) : this.state.flag ? (
      <img
        src={checkmarkgray}
        width='35'
        height='35'
        style={{ marginLeft: "7px" }}
      />
    ) : (
      <img src={check} width='35' height='35' style={{ marginLeft: "7px" }} />
    );
  };

  //sets data values in percent based on api data
  setDataInPercent = () => {
    if (this.state.synced_customers.toFixed(0) === 100) {
      this.dismissCall();
    } else {
      let total =
        this.state.total_customers_to_sync > 0
          ? this.state.total_customers_to_sync
          : 0;
      total =
        this.state.total_invoices_to_sync > 0
          ? total + this.state.total_invoices_to_sync
          : total;
      total =
        this.state.total_payments_to_sync > 0
          ? total + this.state.total_payments_to_sync
          : total;
      total =
        this.state.total_services_to_sync > 0
          ? total + this.state.total_services_to_sync
          : total;
      total =
        this.state.total_terms_to_sync > 0
          ? total + this.state.total_terms_to_sync
          : total;
      let sync_count =
        this.state.synced_customers > 0 ? this.state.synced_customers : 0;
      sync_count =
        this.state.synced_invoices > 0
          ? sync_count + this.state.synced_invoices
          : sync_count;
      sync_count =
        this.state.synced_payments > 0
          ? sync_count + this.state.synced_payments
          : sync_count;
      sync_count =
        this.state.synced_terms > 0
          ? sync_count + this.state.synced_terms
          : sync_count;
      sync_count =
        this.state.synced_services > 0
          ? sync_count + this.state.synced_services
          : sync_count;
      if (total > 0) {
        this.setState({
          syncDone: (sync_count * 100) / total,
        });
      } else {
        this.setState({ syncDone: 100 });
      }
    }
  };

  //set synced data
  setSyncData = (data) => {
    this.setState(
      {
        isSyncOn: true,
        timerCount: this.state.timerCount + 1,
        total_customers_to_sync: data.sync_status.total_customers_to_sync,
        synced_customers: data.sync_status.synced_customers,
        synced_invoices: data.sync_status.synced_invoices,
        synced_payments: data.sync_status.synced_payments,
        synced_terms: data.sync_status.synced_terms,
        synced_services: data.sync_status.synced_services,
        total_invoices_to_sync: data.sync_status.total_invoices_to_sync,
        total_payments_to_sync: data.sync_status.total_payments_to_sync,
        total_services_to_sync: data.sync_status.total_services_to_sync,
        total_terms_to_sync: data.sync_status.total_terms_to_sync,
      },
      () => {
        this.setDataInPercent();
        if (this.state.timerCount === 1) {
          this.getBackendResponse();
        }
      }
    );
  };

  //checks sync status and updates data or calls api based on status
  getSyncData = (data) => {
    if (data.sync_status) {
      this.setState({ SyncStatus: data.sync_status.sync_status });
      if (
        data.sync_status.sync_status &&
        data.sync_status.sync_status === "INPROGRESS"
      ) {
        this.setSyncData(data);
      } else if (
        data.sync_status.sync_status &&
        data.sync_status.sync_status === "COMPLETED"
      ) {
        this.dismissCall();
        this.state.isSyncOn && this.setSyncData(data);
        this.setState(
          {
            lastSyncDate: data.sync_status.sync_end_date,
          },
          () => {
            this.props.updateQBInsideBar({
              lastSyncDate: this.state.lastSyncDate,
            });
          }
        );
      }
    } else {
      this.setState({ SyncStatus: null });
    }
  };

  //check QB status connected or disconnected
  checkAuthStatus = (cancel_sync = false) => {
    // this.setState({ isLoading: true });
    API.checkAuthStatus(this.props.user, cancel_sync).then((data) => {
      this.setState((state) => ({
        ...state,
        isQBDataSynced: data.is_qbo_data_synced,
      }));
      if (data && data.status_code && data.status_code === 400) {
        this.setState({ isLoading: false });
        //call to update notification bell count
        Emitter.emit("IS_QUICKBOOK_CONNECTED", false);
      } else if (
        (typeof data != "undefined" && data.data) ||
        (data != null && data.data)
      ) {
        if (data.data.status && data.data.status === "ACTIVE") {
          Emitter.emit("IS_QUICKBOOK_CONNECTED", true);
          this.setState({ isConnectedToQB: true }, () => {
            this.props.updateQBInsideBar({
              isConnectedToQB: this.state.isConnectedToQB,
            });
          });
          this.getSyncData(data.data);
        }
        this.setState({ isLoading: false });
        if (data.data.sync_status.sync_status == "COMPLETED") {
          Emitter.emit("IS_QUICKBOOK_SYNC", false);
          localStorage.removeItem("isSyncQb");

          this.props.syncQbIcon(false);
          this.props.getOrganizationalDetails();
        }
      } else {
        this.dismissCall();
        this.setState({
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
          isLoading: false,
        });
      }
    });
  };

  getBackendResponse = () => {
    this.dismissCall();
    let Interval = setInterval(this.checkAuthStatus, 3000);
    this.setState({ Interval });
  };

  //calls when "Connect To QB" button click
  callAPI = (event) => {
    API.validateQB(this.props.user).then((data) => {
      if ((typeof data != "undefined" || data != null) && data.data) {
        if (typeof data.data != "undefined" || data.data != null) {
          window.open(data.data, "_self");
          this.setState({ isLoading: true });
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  getQBItemCount = () => {
    API.getQbItemCount(this.props.user).then((data) => {
      if (data && data.data) {
        this.setState({
          customer: data.data.customer,
          invoice: data.data.invoice,
          payment: data.data.payment,
          service: data.data.service,
          term: data.data.term,
        });
      } else {
        this.setState({
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
          isLoading: false,
        });
      }
    });
  };

  getQbSetting = () => {
    this.setState({ flag: false, peferenceErrorFlag: false });
    this.setState({
      accountToggle: false,
      invoiceToggle: false,
      paymentToggle: false,
      autoRecToggle: false,
      accountCreateToggle: false,
      autoImportServices: false,
      autoSelectIncome: false,
      inventryTracking: false,
      autoSyncServices: false,
      usingSalesTex: false,
      allowDiscount: false,
      autoImportTerms: false,
    });

    API.getQbSetting(this.props.user).then((data) => {
      if (data && data.status_code && data.status_code === 200) {
        this.setState({ flag: true });
        this.setState({ peferenceErrorFlag: true, peferenceButton: true });
        if (data.data) {
          this.setState({
            accountToggle: data.data.auto_import_accounts,
            invoiceToggle: data.data.auto_import_invoices,
            paymentToggle: data.data.auto_import_payments,
            autoRecToggle: data.data.auto_reconsile_payments,
            accountCreateToggle: data.data.auto_push_accounts,
            autoImportServices: data.data.auto_import_services,
            autoSyncServices: data.data.auto_push_services,
            autoSelectIncome: data.data.auto_select_income_account,
            usingSalesTex: data.data.using_sales_tax,
            allowDiscount: data.data.allow_discount,
            autoImportTerms: data.data.auto_import_terms,
            autoPushTerms: data.data.auto_push_terms,
            inventryTracking: data.data.inventory_tracking,
            isLoading: false,
          });
        } else {
          this.setState({
            accountToggle: true,
            invoiceToggle: true,
            paymentToggle: true,
            autoRecToggle: true,
            accountCreateToggle: true,
            autoImportServices: true,
            autoSelectIncome: true,
            inventryTracking: true,
            autoSyncServices: true,
            usingSalesTex: false,
            allowDiscount: false,
            isLoading: false,
          });
        }
      } else {
        this.setState({
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
          isLoading: false,
        });
      }
    });
  };

  updateQbSettings = (settings) => {
    API.updateQbSettings(this.props.user, settings).then((data) => {
      if (data && data.status_code) {
        if (data.status_code === 200) {
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  toggleButton = (key) => {
    if (key === "ACCOUNT") {
      this.setState({ accountToggle: !this.state.accountToggle }, () => {
        this.updateQbSettings({
          setting_key: "AUTO_IMPORT_ACCOUNTS",
          value: this.state.accountToggle,
        });
      });
    } else if (key === "ACCOUNT_CREATE") {
      this.setState(
        { accountCreateToggle: !this.state.accountCreateToggle },
        () => {
          this.updateQbSettings({
            setting_key: "AUTO_PUSH_ACCOUNTS",
            value: this.state.accountCreateToggle,
          });
        }
      );
    } else if (key === "INVOICE") {
      this.setState({ invoiceToggle: !this.state.invoiceToggle }, () => {
        this.updateQbSettings({
          setting_key: "AUTO_IMPORT_INVOICES",
          value: this.state.invoiceToggle,
        });
      });
    } else if (key === "INVENTORY_TRACKING") {
      this.setState({ inventryTracking: !this.state.inventryTracking }, () => {
        this.updateQbSettings({
          setting_key: "INVENTORY_TRACKING",
          value: this.state.inventryTracking,
        });
      });
    } else if (key === "PAYMENT") {
      this.setState({ paymentToggle: !this.state.paymentToggle }, () => {
        this.updateQbSettings({
          setting_key: "AUTO_IMPORT_PAYMENTS",
          value: this.state.paymentToggle,
        });
      });
    } else if (key === "AUTO-RE") {
      this.setState({ autoRecToggle: !this.state.autoRecToggle }, () => {
        this.updateQbSettings({
          setting_key: "AUTO_RECONCILE_PAYMENTS",
          value: this.state.autoRecToggle,
        });
      });
    } else if (key === "AUTO_IMPORT_SERVICES") {
      this.setState(
        { autoImportServices: !this.state.autoImportServices },
        () => {
          this.updateQbSettings({
            setting_key: "AUTO_IMPORT_SERVICES",
            value: this.state.autoImportServices,
          });
        }
      );
    } else if (key === "AUTO-SYNC-SERVICES") {
      this.setState({ autoSyncServices: !this.state.autoSyncServices }, () => {
        this.updateQbSettings({
          setting_key: "AUTO_PUSH_SERVICES",
          value: this.state.autoSyncServices,
        });
      });
    } else if (key === "AUTO-SELECT-INCOME") {
      this.setState({ autoSelectIncome: !this.state.autoSelectIncome }, () => {
        this.updateQbSettings({
          setting_key: "AUTO_SELECT_INCOME_ACCOUNT",
          value: this.state.autoSelectIncome,
        });
      });
    } else if (key === "USING-SALES-TAX") {
      this.setState({ usingSalesTex: !this.state.usingSalesTex }, () => {
        this.updateQbSettings({
          setting_key: "USING_SALES_TAX",
          value: this.state.usingSalesTex,
        });
      });
    } else if (key === "ALLOWED-DISCOUNT") {
      this.setState({ allowDiscount: !this.state.allowDiscount }, () => {
        this.updateQbSettings({
          setting_key: "ALLOWED_DISCOUNT",
          value: this.state.allowDiscount,
        });
      });
    } else if (key === "AUTO_IMPORT_TERMS") {
      this.setState({ autoImportTerms: !this.state.autoImportTerms }, () => {
        this.updateQbSettings({
          setting_key: "AUTO_IMPORT_TERMS",
          value: this.state.autoImportTerms,
        });
      });
    } else if (key === "AUTO_PUSH_TERMS") {
      this.setState({ autoPushTerms: !this.state.autoPushTerms }, () => {
        this.updateQbSettings({
          setting_key: "AUTO_PUSH_TERMS",
          value: this.state.autoPushTerms,
        });
      });
    }
  };
  renderSyncQB = (value) => {
    value
      ? this.setState({ isSynchFlag: true, isRenderQb: false })
      : this.setState({ isSynchFlag: false, isRenderQb: true });
  };

  renderSyncQBData = () => {
    let percent = this.state.syncDone > 100 ? 100 : this.state.syncDone;
    let { customer, invoice, payment, service, term } = this.state;
    let {
      total_customers_to_sync,
      total_invoices_to_sync,
      total_payments_to_sync,
      total_services_to_sync,
      total_terms_to_sync,
      synced_customers,
      synced_invoices,
      synced_payments,
      synced_services,
      synced_terms,
    } = this.state;
    //here we are taking approx. 1.7 sec sync time for objects Custome, invoice , payment and 0.5 sec for term and service.
    // Remaining time will be calulated by total objects to be sync (TotaToSync) minus Total objects synced in each loop
    let totaToSync =
      parseFloat(total_customers_to_sync * 1.7) +
      parseFloat(total_invoices_to_sync * 1.7) +
      parseFloat(total_payments_to_sync * 1.7) +
      parseFloat(total_services_to_sync * 0.5) +
      parseFloat(total_terms_to_sync * 0.5);
    let totalSync =
      parseFloat(synced_customers * 1.7) +
      parseFloat(synced_invoices * 1.7) +
      parseFloat(synced_payments * 1.7) +
      parseFloat(synced_services * 0.5) +
      parseFloat(synced_terms * 0.5);
    let remainingTime = (totaToSync - totalSync).toFixed(2);
    let initialTime = parseFloat(
      (customer + invoice + payment) * 1.7 + (service + term) * 0.5
    ).toFixed(2);
    if (percent === 100) {
      initialTime = 0;
    }
    return (
      <>
        <div className='small-device-qbview tp-sps setting-side-canvas col1-scroll scrollbar mg-small-device-qb'>
          <div
            className='mg-wt-100'
            style={{ backgroundColor: "white", padding: "20px" }}
          >
            <div
              className='mg-qb-header'
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                marginBottom: "1rem",
              }}
            >
              <span style={{ flex: "1", width: "50%" }}>
                <h3
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Open Sans ,sans-serif",
                    color: "#000000",
                    display: "flex",
                  }}
                  className='mg-head-bold'
                >
                  QuickBooks Online Data Sync
                  <div style={{ marginLeft: "1rem" }}>
                    <img
                      src={
                        this.state.isConnectedToQB
                          ? ConnectedQBIcon
                          : DisconnectedQBIcon
                      }
                      width={35}
                      height={35}
                    />
                  </div>
                </h3>
              </span>
              <div style={{ width: "50%" }} className={styles.btnBox}>
                <SecondaryButton
                  isBackButton={true}
                  text={"Back"}
                  title={`Go Back To \n Account Sync Preferences`}
                  onClick={() => this.renderSyncQB(true)}
                />
                <PrimaryButton
                  text={"Cancel Sync"}
                  isNextButton={false}
                  title={""}
                  onClick={() => this.cancelSyncQBData()}
                />
                <PrimaryButton
                  text={"Next"}
                  isNextButton={true}
                  title={`Continue To \n Team Management`}
                  onClick={() => {
                    this.props.handleAction("INVITE_TEAM_MEMBERS");
                  }}
                />
              </div>
              {/* <div style={{ flex: "1" }}>
                <div className=' space-between'>
                  {this.state.isConnectedToQB ? (
                    <span
                      // className="panel-heading-secondary set-col-con-key"
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Open Sans,sans-serif",
                      }}
                    >
                      QuickBooks Online Status:
                    </span>
                  ) : (
                    ""
                  )}
                  <span
                    style={
                      this.state.isConnectedToQB
                        ? {
                            color: "#0ab894",
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                            paddingLeft: "2px",
                          }
                        : {
                            color: "#fb5c82",
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }
                    }
                  >
                    {this.state.isConnectedToQB ? (
                      " CONNECTED"
                    ) : (
                      <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                        <button onClick={(event) => this.callAPI(event)}>
                          <div className='connect-qb-btn'></div>
                        </button>
                      </div>
                    )}
                  </span>
                </div>
              </div> */}
            </div>
            <h7 className={"subheader-desc-div"}>
              Your QuickBooks Online account is automatically syncing to your
              MakeGood account. In some cases, the sync will take a while but
              you don't have to wait. The next step is to add team members. Just
              click "Next" to go to the Add Team Members step and the syncing
              will continue in the background.
            </h7>
            <div style={{ backgroundColor: "white" }}>
              <div className='loader-div progress-bar-div'>
                <div
                  className='sync-prt'
                  style={{ borderRadius: "20px", width: "100%" }}
                >
                  <div
                    className='sync-fill-loader'
                    style={{
                      width: percent + "%",
                      backgroundColor: "#0ab894",
                      borderRadius: "20px",
                    }}
                  />
                </div>
                <div className='per-text-div mg-text-div'>
                  <div
                    style={{
                      fontFamily: "Open Sans,sans-serif",
                      width: "150px",
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  >
                    {`${percent.toFixed(0)}% Completed`}
                  </div>
                </div>
              </div>
              <div
                className='mg-tac'
                style={{
                  marginLeft: "32%",
                  fontFamily: "Open Sans,sans-serif",
                }}
              >
                Estimated time remaining:{" "}
                {remainingTime > 0
                  ? remainingTime >= 60
                    ? `${parseInt(remainingTime / 60)} minute ${parseInt(
                        remainingTime % 60
                      )} seconds`
                    : `${parseInt(remainingTime)} seconds`
                  : initialTime > 0 && initialTime >= 60
                  ? `${parseInt(initialTime / 60)} minute ${parseInt(
                      initialTime % 60
                    )} seconds`
                  : `${parseInt(initialTime)} seconds`}
              </div>
              {/* <div className='bottum-btns'>
                <div className='go-back-div'>
                  <input
                    type='submit'
                    id='sync_account_step_2_go_back'
                    value='Go Back'
                    style={{
                      borderRadius: "0.5em",
                      width: "200px",
                      height: "50px",
                      marginTop: "20px",
                      border: "2px solid",
                      float: "left",
                    }}
                    onClick={() => this.renderSyncQB(true)}
                  />
                  <span className='go-back-text'>
                    <p style={this.style.pText}>Go back to</p>
                    <p style={this.style.pText}>"Account Sync Preferences"</p>
                  </span>
                </div>
                <div className='cancel-sync-next-btn'>
                  <div className='cancel-sync-div'>
                    <input
                      type='submit'
                      value={"Cancel Sync"}
                      id='sync_account_step_2_cancel_aync'
                      className='cta mg-brand2-color  renderqb-btn mg-checkprefbtn'
                      style={{
                        width: "200px",
                        height: "50px",
                        marginLeft: "0px",
                        borderRadius: "0.5em",
                        marginTop: "20px",
                      }}
                      onClick={() => this.cancelSyncQBData()}
                    />
                  </div>
                  <div>
                    <input
                      type='submit'
                      id='sync_account_step_2_next'
                      value={"Next"}
                      className='cta mg-brand2-color  renderqb-btn mg-checkprefbtn'
                      style={{
                        width: "200px",
                        height: "50px",
                        borderRadius: "0.5em",
                        marginTop: "20px",
                        marginLeft: "0px",
                        float: "right",
                      }}
                      onClick={() => {
                        this.props.handleAction("INVITE_TEAM_MEMBERS");
                      }}
                    />
                    <span
                      style={{
                        marginRight: "20px",
                        marginTop: "20px",
                      }}
                    >
                      <p style={this.style.pText}>Continue to</p>
                      <p style={this.style.pText}>"Team Management"</p>
                    </span>
                  </div>
                </div>
              </div> */}
            </div>
            {this.state.isSyncOn ? (
              <div>
                {this.state.SyncStatus === "COMPLETED"
                  ? () => this.openDashboard()
                  : null}
              </div>
            ) : null}
          </div>
        </div>
        <Redirect
          exact
          from='/welcome-screen'
          to={{
            pathname: "/welcome-screen/account-preferences-sync-quickbook-data",
          }}
          push
        />
      </>
    );
  };

  renderImportPreferences = () => {
    let percent = this.state.syncDone > 100 ? 100 : this.state.syncDone;
    return (
      <>
        <div
          className='tp-sps app-sidebar-layout-canvas-content setting-side-canvas col1-scroll scrollbar'
          style={{ height: "calc(100vh - 5rem)", padding: "20px" }}
        >
          <div
            className='big-device-grad  tp-sp sp '
            style={{ marginLeft: "0px" }}
          >
            <div
              style={{
                marginTop: 0,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className='main-header' style={{}}>
                <h3
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // padding: "0px",
                    // width: "auto",
                    // flex: "2",
                    // fontFamily: "Open Sans ,sans-serif",
                    color: "black",
                    // fontWeight: "600",
                    // width: "50%",
                  }}
                  className='mg-head-bold'
                >
                  Check Account Sync Preferences
                  <div style={{ marginLeft: "1rem" }}>
                    <img
                      src={
                        this.state.isConnectedToQB
                          ? ConnectedQBIcon
                          : DisconnectedQBIcon
                      }
                      width={35}
                      height={35}
                    />
                  </div>
                </h3>
                {/* <div style={{flex: '1'}}></div> */}
                {/* <div
                    style={{
                      height: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      paddingBlock: "10px",
                    }}
                  >
                    {this.state.isConnectedToQB ? (
                      <span
                        // className="panel-heading-secondary set-col-con-key"
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          fontFamily: "Open Sans ,sans-serif",
                          color: "#000000",
                          paddingBottom: "5px",
                        }}
                      >
                        QuickBooks Online Status:
                      </span>
                    ) : (
                      ""
                    )}
                    <span
                      style={
                        this.state.isConnectedToQB
                          ? {
                              color: "#0ab894",
                              fontFamily: "Open Sans",
                              marginLeft: "5px",
                              paddingBottom: "5px",
                              fontSize: "16px",
                            }
                          : {
                              color: "#fb5c82",
                              fontFamily: "Open Sans",
                              marginLeft: "5px",
                            }
                      }
                    >
                      {this.state.isConnectedToQB ? (
                        "CONNECTED"
                      ) : (
                        <div style={{}}>
                          <button onClick={(event) => this.callAPI(event)}>
                            <div className='connect-qb-btn'></div>
                          </button>
                        </div>
                      )}
                    </span>
                  </div> */}
              </div>
              <div style={{ width: "50%" }} className={styles.btnBox}>
                <SecondaryButton
                  isBackButton={true}
                  text={"Back"}
                  title={`Go Back To \nOrganization’s Detail`}
                  onClick={() => {
                    this.props.handleback();
                    this.props.handleAction("ADD_ORG");
                  }}
                />
                <SecondaryButton
                  isBackButton={false}
                  style={{ marginLeft: "1rem" }}
                  text={
                    this.state.peferenceButton
                      ? "Re-Check Preferences"
                      : "Check Preferences"
                  }
                  title={""}
                  onClick={(event) => this.getQbSetting()}
                />

                {this.state.peferenceButton && (
                  <PrimaryButton
                    text={"Next: Sync Data"}
                    isNextButton={true}
                    title={""}
                    onClick={(event) => {
                      this.getQBData();
                      this.renderSyncQB();
                      this.checkAuthStatus();
                      this.getQBItemCount();
                    }}
                  />
                )}
              </div>
            </div>
            <h7
              className='subhead-desc'
              style={{
                fontFamily: "Open Sans ,sans-serif",
                maxWidth: "100%",
              }}
            >
              MakeGood keeps your data in sync with QuickBooks Online. Select
              the Check Preferences button at the bottom of this page to check
              the integration preferences that are enabled for synchronization
              between your QuickBooks Online and MakeGood accounts.
            </h7>
            <div className='pref-selection'>
              <div style={{ display: "flex", width: "80vw" }}>
                <div
                  className='pref-selection-header'
                  style={{ flex: "4", paddingTop: "20px" }}
                >
                  Accounts
                </div>
                <div
                  className='pref-selection-header'
                  style={{ flex: "1", paddingTop: "20px" }}
                >
                  Status
                </div>
                <div
                  className='pref-selection-header'
                  style={{ flex: "1", paddingTop: "10px" }}
                >
                  {/* <div title="Info">
                  <img src={Icon} width="30" height="30" />
                </div> */}
                </div>
              </div>

              <div className='pref-selection-sep' />
              <div className='pref-selection-inner'>
                <div
                  className=''
                  style={{
                    display: "flex",
                    width: "80vw",
                    alignItems: "center",
                  }}
                >
                  <div className='lists' style={{ flex: 4 }}>
                    <p className='pref-selection-rdtx'>
                      Automatically synchronize Accounts between QuickBooks
                      Online and MakeGood
                    </p>
                  </div>
                  <div title='Info' style={{ flex: 1 }}>
                    {this.imgRender(this.state.accountToggle)}
                  </div>

                  <div style={{ flex: 1 }}>
                    <ToggleSwitch
                      isOn={this.state.accountToggle}
                      isDisable={true}
                      toggleButton={() => this.toggleButton("ACCOUNT")}
                      isOnOpacityLess={this.state.accountToggle}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='pref-selection'>
              <div className='pref-selection-header'>Invoices</div>
              <div className='pref-selection-sep' />
              {/* <div className="pref-selection-inner"> */}
              <div
                className='pref-selection-inner-left'
                style={{ display: "flex", width: "80vw", alignItems: "center" }}
              >
                <div className='pref-selection-rdtx' style={{ flex: 4 }}>
                  Automatically synchronize Invoices between QuickBooks Online
                  and MakeGood
                </div>

                <div title='Info' style={{ flex: 1 }}>
                  {this.imgRender(this.state.invoiceToggle)}
                </div>
                <div style={{ flex: 1 }}>
                  <ToggleSwitch
                    isOn={this.state.invoiceToggle}
                    isDisable={true}
                    toggleButton={() => this.toggleButton("INVOICE")}
                    isOnOpacityLess={this.state.invoiceToggle}
                  />
                </div>
              </div>
              {/* </div> */}
            </div>
            <div className='pref-selection'>
              <div className='pref-selection-header'>Payments</div>
              <div className='pref-selection-sep' />
              <div className='pref-selection-inner'>
                <div
                  className=''
                  style={{
                    display: "flex",
                    width: "80vw",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: "4" }}>
                    <p className='pref-selection-rdtx'>
                      Automatically synchronize Payments between QuickBooks
                      Online and MakeGood
                    </p>
                  </div>
                  <div style={{ flex: "1" }}>
                    <span title='Info'>
                      {this.imgRender(this.state.paymentToggle)}
                    </span>
                  </div>

                  <div style={{ flex: "1" }}>
                    <ToggleSwitch
                      isOn={this.state.paymentToggle}
                      isDisable={true}
                      toggleButton={() => this.toggleButton("PAYMENT")}
                      isOnOpacityLess={this.state.paymentToggle}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='pref-selection'>
              <div className='pref-selection-header'>Goods & Services</div>
              <div className='pref-selection-sep' />
              <div className='pref-selection-inner'>
                <div
                  className=''
                  style={{
                    display: "flex",
                    width: "80vw",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: "4" }}>
                    <p className='pref-selection-rdtx'>
                      Auto-import Products & Services items from your QuickBooks
                      Online account
                    </p>
                  </div>

                  <div title='Info' style={{ flex: "1" }}>
                    {this.imgRender(this.state.autoImportServices)}
                  </div>
                  <div style={{ flex: "1" }}>
                    <ToggleSwitch
                      isOn={this.state.autoImportServices}
                      isDisable={true}
                      toggleButton={() =>
                        this.toggleButton("AUTO_IMPORT_SERVICES")
                      }
                      isOnOpacityLess={this.state.autoImportServices}
                    />
                  </div>
                </div>
              </div>
              <div className='pref-selection-inner'>
                <div
                  className=''
                  style={{
                    display: "flex",
                    width: "80vw",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: "4" }}>
                    <p className='pref-selection-rdtx'>
                      Auto-Sync Goods & Services to QuickBooks Online
                    </p>
                  </div>
                  <div title='Info' style={{ flex: "1" }}>
                    {this.imgRender(this.state.autoSyncServices)}
                  </div>
                  <div style={{ flex: "1" }}>
                    <ToggleSwitch
                      isOn={this.state.autoSyncServices}
                      isDisable={true}
                      toggleButton={() =>
                        this.toggleButton("AUTO-SYNC-SERVICES")
                      }
                      isOnOpacityLess={this.state.autoSyncServices}
                    />
                  </div>
                </div>
              </div>
              <div className='pref-selection-inner'>
                <div
                  className=''
                  style={{
                    display: "flex",
                    width: "80vw",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: "4" }}>
                    <p className='pref-selection-rdtx'>
                      Auto Select the QuickBooks Online Income account
                    </p>
                  </div>
                  <div title='Info' style={{ flex: "1" }}>
                    {this.imgRender(this.state.autoSelectIncome)}
                  </div>
                  <div style={{ flex: "1" }}>
                    <ToggleSwitch
                      isOn={this.state.autoSelectIncome}
                      isDisable={true}
                      toggleButton={() =>
                        this.toggleButton("AUTO-SELECT-INCOME")
                      }
                      isOnOpacityLess={this.state.autoSelectIncome}
                    />
                  </div>
                </div>
              </div>
              <div className='pref-selection-inner'>
                <div
                  className=''
                  style={{
                    display: "flex",
                    width: "80vw",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: "4" }}>
                    <p className='pref-selection-rdtx'>
                      Check QuickBooks Online subscription to see if Inventory
                      Tracking is available
                    </p>
                    {this.state.peferenceErrorFlag &&
                    !this.state.inventryTracking ? (
                      <>
                        <p
                          style={{
                            fontFamily: "Open Sans",
                            color: "#e61111",
                            paddingRight: "30px",
                            margin: "0px",
                          }}
                        >
                          Inventory Tracking is unavailable. Upgrade QuickBooks
                          Online subscription to a version that supports
                          Inventory Tracking
                        </p>
                        <p
                          style={{
                            fontFamily: "Open Sans",
                            color: "#0ab894",
                            paddingRight: "30px",
                          }}
                        >
                          *Inventory Tracking in MakeGood will be available but
                          Inventory Tracking data will not synchronize to
                          QuickBooks Online without the proper subscription
                          level in your QuickBooks Online account
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div title='Info' style={{ flex: "1" }}>
                    {this.imgRenderError(this.state.inventryTracking)}
                  </div>
                  <div style={{ flex: "1" }}>
                    <ToggleSwitch
                      isOn={this.state.inventryTracking}
                      isDisable={true}
                      toggleButton={() =>
                        this.toggleButton("INVENTORY_TRACKING")
                      }
                      isOnOpacityLess={this.state.autoSelectIncome}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='pref-selection'>
              <div className='pref-selection-header'>Sales Tax</div>
              <div className='pref-selection-sep' />
              <div className='pref-selection-inner'>
                <div
                  className=''
                  style={{
                    display: "flex",
                    width: "80vw",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: "4" }}>
                    <p className='pref-selection-rdtx'>
                      Use QuickBooks Online Automated Sales Tax when calculating
                      Sales Tax on Invoices
                    </p>
                    {this.state.peferenceErrorFlag &&
                    !this.state.usingSalesTex ? (
                      <p
                        style={{
                          fontFamily: "Open Sans",
                          color: "#e61111",
                          paddingRight: "30px",
                        }}
                      >
                        The Sales Tax Preference is disabled in your QuickBooks
                        Online account. If your organization uses Sales Tax then
                        MakeGood recommends enabling the Sales Tax Preference in
                        your QuickBooks Online account for accurate Sales Tax
                        synchronization .
                        <p style={{ color: "#0ab894" }}>
                          <span>Hint: </span>*If you don't use Sales Tax then
                          click the Next button to keep going.
                        </p>
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div title='Info' style={{ flex: "1" }}>
                    {this.imgRenderError(this.state.usingSalesTex)}
                  </div>
                  <div style={{ flex: "1" }}>
                    <ToggleSwitch
                      isOn={this.state.usingSalesTex}
                      isDisable={true}
                      toggleButton={() => this.toggleButton("USING-SALES-TAX")}
                      isOnOpacityLess={this.state.usingSalesTex}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='pref-selection'>
              <div className='pref-selection-header'>Discount</div>
              <div className='pref-selection-sep' />
              <div className='pref-selection-inner'>
                <div
                  className=''
                  style={{
                    display: "flex",
                    width: "80vw",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: "4" }}>
                    <p className='pref-selection-rdtx'>
                      Automatically associate Early Payer Discounts with the
                      default Discount Account in QuickBooks Online
                    </p>
                    {this.state.peferenceErrorFlag &&
                    !this.state.allowDiscount ? (
                      <p
                        style={{
                          fontFamily: "Open Sans",
                          color: "#e61111",
                          paddingRight: "30px",
                        }}
                      >
                        The Discounts Preference within your QuickBooks Online
                        account is disabled. If your organization is using or
                        will be implementing discounts then MakeGood strongly
                        recommends enable the Discounts Preference in your
                        QuickBooks Online account.
                        <p style={{ color: "#0ab894" }}>
                          Hint: *If you don't use Discounts and don't plan on
                          using discounts then click the Next button to keep
                          going.
                        </p>
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div title='Info' style={{ flex: "1" }}>
                    {this.imgRenderError(this.state.allowDiscount)}
                  </div>
                  <div style={{ flex: "1" }}>
                    <ToggleSwitch
                      isOn={this.state.allowDiscount}
                      isDisable={true}
                      toggleButton={() => this.toggleButton("ALLOWED-DISCOUNT")}
                      isOnOpacityLess={this.state.allowDiscount}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* TO_DO_MAK490 */}
            <div className='pref-selection'>
              <div className='pref-selection-header'>Terms</div>
              <div className='pref-selection-sep' />
              <div className='pref-selection-inner'>
                <div
                  className=''
                  style={{
                    display: "flex",
                    width: "80vw",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: "4" }}>
                    <p className='pref-selection-rdtx'>
                      Automatically synchronize Terms between QuickBooks Online
                      and MakeGood
                    </p>
                  </div>
                  <div title='Info' style={{ flex: "1" }}>
                    {this.imgRender(this.state.autoImportTerms)}
                  </div>
                  <div style={{ flex: "1" }}>
                    <ToggleSwitch
                      isOn={this.state.autoImportTerms}
                      isDisable={true}
                      toggleButton={() =>
                        this.toggleButton("AUTO_IMPORT_TERMS")
                      }
                      isOnOpacityLess={this.state.autoImportTerms}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='sync-btn mg-qbbtns'>
              <div style={{ flex: "2" }}>
                <input
                  type='submit'
                  value='Go Back'
                  id='sync_account_step_1_go_back'
                  // className="cta mg-brand2-color emp-add-btn-mob"
                  style={{
                    borderRadius: "0.5em",
                    width: "200px",
                    height: "50px",
                    marginTop: "20px",
                    border: "2px solid",
                    float: "left",
                  }}
                  onClick={() => {
                    this.props.handleback();
                    this.props.handleAction("ADD_ORG");
                  }}
                />
                <span className='go-back-text'>
                  <p style={this.style.pText}>Go back to</p>
                  <p style={this.style.pText}>"Organization’s Detail"</p>
                </span>
              </div>
              <div style={{ flex: "1" }}>
                <input
                  type='submit'
                  id='sync_account_step_1_recheck_or_check_preferencs'
                  value={
                    this.state.peferenceButton
                      ? "Re-Check Preferences"
                      : "Check Preferences"
                  }
                  className='cta mg-brand2-color mg-checkprefbtn'
                  style={{
                    width: "auto",
                    height: "50px",
                    borderRadius: "0.5em",
                    marginTop: "20px",
                    float: "right",
                  }}
                  onClick={(event) => this.getQbSetting()}
                />
              </div>
              {this.state.peferenceButton ? (
                <div style={{ flex: "1" }}>
                  <input
                    type='submit'
                    value={"Next: Sync Data"}
                    id='sync_account_step_1_sync_data'
                    className='cta mg-brand2-color mg-checkprefbtn'
                    onClick={(event) => {
                      this.getQBData();
                      this.renderSyncQB();
                      this.checkAuthStatus();
                      this.getQBItemCount();
                    }}
                    style={{
                      width: "auto",
                      height: "50px",
                      borderRadius: "0.5em",
                      marginTop: "20px",
                      float: "right",
                    }}
                  />
                </div>
              ) : (
                <div style={{ flex: "1" }}> </div>
              )}
            </div> */}
          </div>
        </div>
        <Redirect
          exact
          from='/welcome-screen'
          to={{
            pathname: "/welcome-screen/account-preferences-sync-quickbook-data",
          }}
          push
        />
      </>
    );
  };

  renderConnectQB = () => {
    return (
      <div className='syncViewPage-alignment'>
        <div className='big-device-grad  tp-sp'>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className='he-hed' style={{ width: "50%" }}>
                Hello!
              </div>
              <div style={{ width: "50%" }} className={styles.btnBox}>
                <SecondaryButton
                  isBackButton={true}
                  text={"Back"}
                  title={""}
                  onClick={() => {
                    this.state.isQBDataSynced
                      ? this.props.handleAction("ADD_ORG")
                      : this.props.backToAccountConnection();
                  }}
                />
                <PrimaryButton
                  isNextButton={true}
                  text={"Next"}
                  title={""}
                  onClick={() => {
                    this.props.handleAction("INVITE_TEAM_MEMBERS");
                  }}
                />
              </div>
            </div>
          </div>
          <div className='set-half'>
            <p className='sub-hed-p'>
              You’re either connecting to QuickBooks for the first time or your
              current connection has expired. Let’s take the next step and
              connect to your QuickBooks Online account by clicking the button
              below.
            </p>
            <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
              <button onClick={(event) => this.callAPI(event)}>
                <div className='connect-qb-btn'></div>
              </button>
            </div>
            <div>
              <h4>Questions?</h4>
              <p className='qb-p'>
                Click the HELP button at any time for chat, email, or phone
                support, or to visit our FAQ section.
              </p>
            </div>
          </div>
          {/* <div className='button-left' style={{ marginTop: "10px" }}>
            <input
              type='submit'
              value='Go Back'
              id='invite_team_go_back'
              style={{
                borderRadius: "0.5em",
                width: "180px",
                height: "45px",
                marginTop: "10px",
                border: "2px solid",
                fontFamily: "Open Sans",
                fontSize: "16px",
                fontWeight: "500",
              }}
              onClick={() => {
                this.state.isQBDataSynced
                  ? this.props.handleAction("ADD_ORG")
                  : this.props.backToAccountConnection();
              }}
            />
            <input
              type='submit'
              id='sync_account_step_2_next'
              value={"Next"}
              className='cta mg-brand2-color  renderqb-btn mg-checkprefbtn'
              style={{
                width: "180px",
                height: "45px",
                borderRadius: "0.5em",
                marginTop: "10px",
                marginLeft: "0px",
                // marginRight: '10px',
                float: "right",
              }}
              onClick={() => {
                this.props.handleAction("INVITE_TEAM_MEMBERS");
              }}
            />
          </div> */}
        </div>
      </div>
    );
  };

  render() {
    const { appName, isEditPref, disconnected } = this.props;
    const { isLoading, error, isConnectedToQB, isConnectedToAccountSys } =
      this.state;
    return isLoading ? (
      <div style={{ marginTop: "20%", marginLeft: "40%" }}>
        <AppCanvasLoader />
      </div>
    ) : (
      <div
        className={
          this.state.isRenderQb
            ? "consumer-help-qbsync"
            : "consumer-help" + " mg-wt-100"
        }
      >
        <Helmet title={appName + " | QuickBooks Preferences"}></Helmet>
        {isConnectedToAccountSys
          ? this.state.isSynchFlag
            ? this.renderImportPreferences()
            : this.renderSyncQBData()
          : this.renderConnectQB()}
      </div>
    );
  }
}
export default QuickBookDataSyncView;
