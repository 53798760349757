import moment from "moment";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
// import ReactGA from "react-ga";
import { Redirect } from "react-router-dom";
import shortid from "shortid";
import viewIcon from "../../../assets/icons/view.png";
//import companyData from "../../../assets/styles/theme.json";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import ClientsCardView from "../../../components/CardView/ClientsCardView";
import ClientsCreditMemoSidebar from "../../../components/Clients/Credit Memo/ClientCreditMemoSidebar";
import InvoiceHistory from "../../../components/Clients/Invoice/invoiceHistory";
import InvoiceTasks from "../../../components/Clients/Invoice/invoiceTasks";
import CreditMemoLineDetails from "../../../components/Clients/Credit Memo/CreditMemoLineDetails";
import InvoiceObjectList from "../../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import * as API from "../../../utils/api";
import downloadImg from "../../../assets/icons/download.png";
import deleteImg from "../../../assets/icons/delete.png";
import editImg from "../../../assets/icons/edit_blue.svg";
import EditIcon from "../../../assets/icons/edit_white.svg";
import HistoryIcon from "../../../assets/icons/history.svg";
import { getDollarAmount } from "../../../utils/helpers";
import Emitter from "../../../utils/event-emitter";

class ClientsCreditMemoView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceDetails: [],
      creditMemoDetails: [],
      taskCategory: "Comms",
      selectedButton: 1,
      isShowHistory: false,
      expandPayment: false,
      expandDoc: false,
      isLoading: true,
      showLoader: false,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
      companyData: {},
    };
  }

  componentDidMount() {
    this.getOrganizationData();
    this.getCreditMemoDetails();
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
    // ReactGA.pageview("/ClientsCreditMemoView");
  }

  componentDidUpdate(props) {
    if (this.props.match.params.id != props.match.params.id) {
      this.getCreditMemoDetails();
    }
  }

  async getOrganizationData() {
    await API.getOrganizationData().then((data) => {
      this.setState({
        companyData: data,
      });
    });
  }

  reloadData = () => {
    this.getCreditMemoDetails();
  };

  updateDocument = (data) => {
    let { creditMemoDetails } = this.state;
    if (data && data.length) {
      creditMemoDetails.document = data;
      this.setState({ creditMemoDetails });
    }
  };

  getDate = (date) => {
    if (date) {
      return moment.utc(date).format("MM/DD/YYYY");
    }
    return "";
  };

  handleHeaderExpander = (event, type) => {
    if (type === "Payment") {
      this.setState({ expandPayment: !this.state.expandPayment });
    } else {
      this.setState({ expandDoc: !this.state.expandDoc });
    }
  };

  getObjectListData = (data, type) => {
    let headings = [],
      columns = [];
    headings =
      type === "Payment"
        ? [
            {
              name: "Date",
              style: {
                width: "20%",
                display: "flex",
                justifyContent: "flex-start",
              },
            },
            {
              name: "Amount",
              style: {
                width: "20%",
                display: "flex",
                justifyContent: "center",
              },
            },
            {
              name: "Pymt Method",
              style: {
                width: "20%",
                display: "flex",
                justifyContent: "center",
              },
            },
            {
              name: "Type",
              style: {
                width: "20%",
                display: "flex",
                justifyContent: "center",
              },
            },
            {
              name: "Customer ID",
              style: {
                width: "20%",
                display: "flex",
                justifyContent: "center",
              },
            },
          ]
        : type === "Document"
        ? [
            {
              name: "Name",
              style: {
                width: "25%",
                display: "flex",
                justifyContent: "flex-start",
              },
            },
            {
              name: "Description",
              style: {
                width: "25%",
                display: "flex",
                justifyContent: "center",
              },
            },
            {
              name: "Type",
              style: {
                width: "25%",
                display: "flex",
                justifyContent: "center",
              },
            },
            {
              name: "Action",
              style: {
                width: "25%",
                display: "flex",
                justifyContent: "center",
              },
            },
          ]
        : type == "note"
        ? [
            {
              name: "Name",
              style: {
                width: "20%",
                display: "flex",
                justifyContent: "flex-start",
              },
            },
            {
              name: "Date",
              style: {
                width: "20%",
                display: "flex",
                justifyContent: "center",
              },
            },
            {
              name: "Note",
              style: {
                width: "60%",
                display: "flex",
                justifyContent: "center",
              },
            },
          ]
        : [];

    columns =
      type === "Document"
        ? data.map((row) => {
            return {
              emptyPrimary: true,
              secondaryColumns: [
                {
                  key: "Name",
                  title: row.name,
                  style: {
                    width: "25%",
                    display: "flex",
                    justifyContent: "flex-start",
                  },
                },
                {
                  key: "Description",
                  title: row.description,
                  style: {
                    width: "25%",
                    display: "flex",
                    justifyContent: "center",
                  },
                },
                {
                  key: "Type",
                  title: row.document_type ? row.document_type.name : "",
                  style: {
                    width: "25%",
                    display: "flex",
                    justifyContent: "center",
                  },
                },
                {
                  key: "Action",
                  title: this.documentAction(row),
                  style: {
                    width: "25%",
                    display: "flex",
                    justifyContent: "center",
                  },
                },
              ],
            };
          })
        : type === "Payment"
        ? data.map((row) => {
            return {
              emptyPrimary: true,
              secondaryColumns: [
                {
                  key: "Date",
                  title: row.date
                    ? moment(row.date).utc().format("MM/DD/YYYY")
                    : "--/--/----",
                  style: {
                    width: "20%",
                    display: "flex",
                    justifyContent: "flex-start",
                  },
                },
                {
                  key: "Amount",
                  title: getDollarAmount(
                    row.is_cancelled ? row.cancel_amount : row.amount
                  ),
                  style: {
                    width: "20%",
                    display: "flex",
                    justifyContent: "center",
                  },
                },
                {
                  key: "Pymt Method",
                  title: row.payment_type ? row.payment_type : "n/a",

                  style: {
                    width: "20%",
                    display: "flex",
                    justifyContent: "center",
                  },
                },
                {
                  key: "Type",
                  title: row.is_cancelled
                    ? "Cancelled"
                    : row.is_refund
                    ? "Refunded"
                    : row.status === "SUCCESS"
                    ? "Paid"
                    : row.status,
                  style: {
                    width: "20%",
                    display: "flex",
                    justifyContent: "center",
                  },
                },
                {
                  key: "Customer ID",
                  title: row.charge_id ? row.charge_id : "n/a",
                  style: {
                    width: "20%",
                    display: "flex",
                    justifyContent: "center",
                  },
                },
              ],
            };
          })
        : type === "note"
        ? data.map((row) => {
            return {
              emptyPrimary: true,
              secondaryColumns: [
                {
                  key: "Name",
                  title: row.display_name
                    ? row.display_name
                    : row.first_name + " " + row.last_name,
                  style: {
                    width: "20%",
                    display: "flex",
                    justifyContent: "flex-start",
                  },
                },
                {
                  key: "Date",
                  title: this.getDate(row.created_at),
                  style: {
                    width: "20%",
                    display: "flex",
                    justifyContent: "center",
                  },
                },
                {
                  key: "Note",
                  title: row.note,
                  style: {
                    width: "60%",
                    display: "flex",
                    justifyContent: "center",
                  },
                },
              ],
            };
          })
        : [];

    return {
      headings,
      columns,
    };
  };

  documentAction = (row) => {
    let imgExtension = row.document_name && row.document_name.split(".").pop();
    let fileSizeCheck =
      row.file_size_in_mb && row.file_size_in_mb > 3 ? true : false;
    let flag =
      imgExtension &&
      (imgExtension.toLowerCase() === "xls" ||
        imgExtension.toLowerCase() === "xlsx" ||
        imgExtension.toLowerCase() === "doc" ||
        imgExtension.toLowerCase() === "docx")
        ? false
        : true;
    flag = fileSizeCheck ? false : flag;
    return (
      <div>
        <a
          className='make-payment-button'
          style={{ cursor: "pointer", marginRight: 15 }}
          title='Download'
          onClick={() =>
            this.downloadAttachedDocuement(row.document_id, row.name)
          }
        >
          <img src={downloadImg} width='16' height='16' />
        </a>
        <button
          title='Edit'
          style={{
            background: "transparent",
            cursor: "pointer",
            marginRight: 15,
          }}
          onClick={() => this.attchDocument(row, true)}
        >
          <img src={editImg} width='16' height='16' />
        </button>
        <button
          title='Delete'
          style={{
            background: "transparent",
            cursor: "pointer",
            marginRight: 15,
          }}
          onClick={() => this.deleteAttachment(row.document_id)}
        >
          <img src={deleteImg} width='28' height='28' />
        </button>
        {flag && (
          <button
            title='View'
            className='view-button'
            style={{
              background: "transparent",
              cursor: "pointer",
            }}
            onClick={() => this.viewValidationDoc(row)}
          >
            <img src={viewIcon} width='16' height='16' />
          </button>
        )}
      </div>
    );
  };

  downloadAttachedDocuement = (doc_id, fileName) => {
    const { user } = this.props;
    API.getAttachedDocuement(user, doc_id, "credits").then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let flag = data.data.includes("base64");
          if (!flag) {
            data.data = "data:application/pdf;base64," + data.data;
          }
          let url = data.data;
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  viewValidationDoc = (row) => {
    let props = {
      image: row.document,
      type: row.document_name,
      doc_name: row.name,
      doc_id: row.document_id,
      arrange_pdf: true,
      user: this.props.user,
      invoiceId: this.state.invoiceDetails.invoice_number,
      from: "credit_memo",
    };
    this.props.updateModalState(true, "SHOW_IMAGE", props);
  };

  attchDocument = (editData, isEditable) => {
    let props = {
      rowData: this.state.creditMemoDetails,
      consumer:
        this.state.creditMemoDetails && this.state.creditMemoDetails.user
          ? this.state.creditMemoDetails.user
          : "",
      editData: editData,
      isEditable: isEditable,
      reloadData: this.reloadData,
      updateDocument: this.updateDocument,
      from: "credit_memo",
      mgModalTitle: editData ? "Edit Document" : "Upload Document",
    };
    this.props.updateModalState(true, "VALIDATION_DOCUMENT", props);
  };

  deleteAttachment = (doc_id) => {
    let message = "Do you want to delete document?";
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: message,
      confirmClick: () => {
        API.deleteVerificationDocument(this.props.user, doc_id, "credits").then(
          (data) => {
            this.props.updateModalState(true, "SUCCESS", {
              message: data.message,
            });
            if (data && data.data) {
              this.updateDocument(data.data);
            }
          }
        );
      },
      closeOnClickOutside: true,
    });
  };

  renderPaymentAndDoc = (data, header, type, emptyMsg) => {
    let { expandDoc, expandPayment } = this.state;
    let flag =
      expandDoc && type === "Document"
        ? true
        : expandPayment && type === "Payment"
        ? true
        : false;
    let style = type === "Payment" ? { marginBottom: "2em" } : {};
    return (
      <div style={style}>
        {this.getClickableHeader(header, type, data)}
        {flag && (
          <div>
            {data && data.length ? (
              <div>
                <ClientsCardView data={this.getObjectListData(data, type)} />
                <InvoiceObjectList
                  data={this.getObjectListData(data, type)}
                  hideForMobile={true}
                  removeBottomSpace={true}
                  optionalTableClasses={
                    type === "Document" ? "invoice-doc" : ""
                  }
                  memoView={true}
                />
              </div>
            ) : (
              this.renderEmpty(emptyMsg)
            )}
          </div>
        )}
      </div>
    );
  };

  getClickableHeader = (header, type, data) => {
    let { expandDoc, expandPayment } = this.state;
    let count = data && data.length ? data.length : 0;
    let flag =
      expandDoc && type === "Document"
        ? true
        : expandPayment && type === "Payment"
        ? true
        : false;
    return (
      <div className='in-new-btn'>
        <div className='ic-wt-fields'>
          <div className='he-hed he-sec-hed'>
            {header} ({count})
          </div>
          <a
            key={shortid.generate()}
            className={`client-caret expander-caret${
              flag ? " down-caret" : ""
            }`}
            style={{ margin: "12px 0px 0px 12px" }}
            onClick={(event) => this.handleHeaderExpander(event, type)}
          />
        </div>
        {type == "Document" ? (
          <button className='attach-btn' onClick={() => this.attchDocument()}>
            Attach
          </button>
        ) : null}
      </div>
    );
  };

  handleEditClick = (e) => {
    let { history } = this.props;

    if (this.state.creditMemoDetails.user.is_disabled) {
      this.props.updateModalState(true, "ERROR", {
        message:
          "This account is currently disabled, restricting any add, edit, or delete actions.",
        title: "Alert",
      });
    } else {
      history.push({
        pathname: `/client/add/creditMemo`,
        state: {
          creditMemoDetails: this.state.creditMemoDetails,
          backPath: `/client/creditMemo/${this.props.match.params.id}`,
          accountPath: history.location.state.accountPath,
        },
      });
    }
  };

  headerUtilities = () => {
    const { creditMemoDetails } = this.state;
    return (
      <div
        className='app-section-header-utilities'
        style={{ marginBottom: "1.2em" }}
      >
        <div className='admin-search-input-container main-header-search'>
          <div>
            Source:{" "}
            <span style={{ fontWeight: 600 }}>
              {creditMemoDetails && creditMemoDetails.qb_credit_memo_id
                ? "QuickBooks"
                : "MakeGood"}
            </span>
          </div>
        </div>
      </div>
    );
  };

  getCreditMemoDetails = () => {
    let { user } = this.props;
    const creditMemoId = this.props.match.params.id;
    API.getCreditMemoDetails(user, creditMemoId).then((data) => {
      Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if (
        (typeof data != "undefined" || data != null) &&
        data.message === "success" &&
        !data.error
      ) {
        if (data.data) {
          this.setState({ creditMemoDetails: data.data }, () => {
            // this.updateInvoiceData();
          });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  addNewwNote = () => {
    let optionalProps = {
      action: "AddTaskNote",
      handleAddTaskNote: this.handleAddTaskNote,
    };
    this.props.updateModalState(true, "ACCEPT_REJECT_NOTES", optionalProps);
  };

  handleAddTaskNote = (note) => {
    const { user } = this.props;
    const { creditMemoDetails } = this.state;
    let payload = {
      credit_memo_id: creditMemoDetails.id,
      note: note,
    };
    API.addCreditMemoNote(user, payload).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.status_code === 200
      ) {
        //close modal
        this.props.updateModalState(false, "ACCEPT_REJECT_NOTES");
        this.reloadData();
      } else {
        if (data.status_code == 422) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
          this.reloadData();
        }
        //close modal
        this.props.updateModalState(false, "ACCEPT_REJECT_NOTES");
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  renderEmpty = (msg) => {
    return (
      <div
        className='admin-admins-empty-state'
        style={{ padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF" }}
      >
        <p>{msg}</p>
      </div>
    );
  };

  render() {
    const { appName, user, location, back_Path } = this.props;
    const { isLoading, error, creditMemoDetails, isShowHistory, showLoader } =
      this.state;
    // console.log('--------------------------------back-path----',history.location.state.backPath)
    let backPath =
      location && location.state && location.state.accountPath
        ? location.state.accountPath
        : back_Path;
    const props = this.props;
    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }
    let { notes } = creditMemoDetails;

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className='client-scroll-dashboard scrollbar'>
        <Helmet>
          <title>
            {`${appName} | Credit-Memo# ${creditMemoDetails.credit_memo_number}`}
            {/* {appName} | Credit-Memo# {creditMemoDetails.credit_memo_number} */}
          </title>
        </Helmet>
        <div className='app-sidebar-layout-canvas'>
          <ClientsCreditMemoSidebar
            {...props}
            user={user}
            backPath={backPath}
            creditMemoDetails={creditMemoDetails}
            title={this.state.companyData.name}
          />
          {showLoader ? (
            <div className='app-sidebar-layout-canvas-content'>
              <AppCanvasLoader />
            </div>
          ) : (
            <div className='app-sidebar-layout-canvas-content setting-side-canvas big-device-grad col1-scroll scrollbar'>
              <div className='main-header'>
                <div className='he-hed edit-invoice'>
                  Credit Memo# {creditMemoDetails.credit_memo_number}
                  <div className='edit-circle'>
                    <img
                      src={EditIcon}
                      height={15}
                      width={15}
                      onClick={(event) => this.handleEditClick(event)}
                    />
                  </div>
                  {/* <div className="history-circle">
                                            <img
                                                src={HistoryIcon}
                                                height={30}
                                                width={31}
                                                onClick={(event) => this.showHistory(true)}
                                            />
                                        </div> */}
                </div>
                {this.headerUtilities()}
              </div>
              {/**TO_DO */}
              <CreditMemoLineDetails creditMemoDetails={creditMemoDetails} />

              <div className='in-new-btn'>
                <div className='he-hed he-sec-hed'>
                  Notes ({notes && notes.length ? notes.length : 0})
                </div>
                <button onClick={() => this.addNewwNote()}>New Note</button>
              </div>
              {notes && notes.length ? (
                <div>
                  <ClientsCardView
                    data={this.getObjectListData(notes, "note")}
                  />
                  <InvoiceObjectList
                    data={this.getObjectListData(notes, "note")}
                    hideForMobile={true}
                    optionalTableClasses='in-task'
                  />
                </div>
              ) : (
                this.renderEmpty(`There are currently no notes to review.`)
              )}

              {this.renderPaymentAndDoc(
                creditMemoDetails.document,
                "Documents",
                "Document",
                "No documents available"
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ClientsCreditMemoView;
